import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// ================================================================================================

export interface FilterHeaderProps {
  first?: boolean
  numOfFilters?: number
  onToggleOpen: () => void
  open: boolean
  title: string
}

const FilterHeader: React.FC<FilterHeaderProps> = ({
  first,
  numOfFilters,
  onToggleOpen,
  open,
  title
}) => {
  return (
    <Wrapper borderTop={!first} onClick={onToggleOpen}>
      <span>{`${title} ${numOfFilters ? `(${numOfFilters})` : ''}`}</span>
      <Svg
        animate={{ rotate: open ? 180 : 0 }}
        height="12px"
        transition="tween"
        viewBox="0 0 128 128"
        width="12px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M63.703 107L127 55.02v-21L63.746 85.963 1 34v21.072L63.703 107z" fill="#000" />
      </Svg>
    </Wrapper>
  )
}

export default FilterHeader

// ================================================================================================

const Wrapper = styled(motion.button)`
  cursor: pointer;
  height: 3rem;
  margin-top: 0;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${({ borderTop, theme }) =>
    borderTop ? `1px solid ${theme && theme.neutral2 ? theme.neutral2 : '#f6f6f6'}` : 'none'};

  > span {
    font-size: 0.9375rem;
    color: ${({ theme }) => (theme && theme.text ? theme.text : '#111')};
  }
`

const Svg = styled(motion.svg)`
  color: ${({ theme }) => (theme && theme.text ? theme.text : '#111')};
  transform-origin: center !important;
`
