import { Dot, DotGroup, Ring, Segment } from './types'

// ================================================================================================

export const getDotPosition = (
  radius,
  cx,
  cy,
  dot: Dot,
  dots: Dot[],
  rings: Ring[],
  segments: Segment[],
  ringValue: string,
  segmentValue: string,
  cutout?: number
): { x: number; y: number } => {
  // ==============================
  // Check if ring and segment active

  const currentRing = rings.find((r: Ring) =>
    r.boundries && r.boundries.length === 2
      ? dot[ringValue] >= r.boundries[0] && dot[ringValue] <= r.boundries[1]
      : dot[ringValue] === r.id
  )
  const currentSegment = segments.find((s: Segment) => s.id === dot[segmentValue])

  if (currentRing && currentSegment) {
    // ==============================
    // Locate current dot in segments and rings

    const groupedDots = groupDots(dots, segments, segmentValue)

    const currentDotGroup = groupedDots.find(
      (group: DotGroup) => group.segmentLabel === currentSegment.label
    )

    if (currentDotGroup && currentDotGroup.dots && currentDotGroup.dots.length > 0) {
      const currentDotIndex = currentDotGroup.dots.findIndex((d: Dot) => d.id === dot.id)
      const currentRingIndex = rings.findIndex((r: Ring) => r.id === currentRing.id)
      const currentSegmentIndex = segments.findIndex((s: Segment) => s.id === dot[segmentValue])

      // ==============================
      // Transform values

      const cutoutAdd = cutout ? 180 + cutout / 2 : 0
      const fullCircle = cutout ? 360 - cutout : 360

      // Segment (y)
      const segmentStart = currentSegmentIndex * (fullCircle / segments.length) + cutoutAdd
      const segmentEnd =
        currentSegmentIndex * (fullCircle / segments.length) +
        fullCircle / segments.length +
        cutoutAdd
      const dotsInSegment = currentDotGroup.dots.length // for dot distribution in segment
      const segmentPos =
        segmentStart + ((segmentEnd - segmentStart) / (dotsInSegment + 1)) * (currentDotIndex + 1)

      // Ring (x)
      const ringStart = (rings.length - 1 - currentRingIndex) * (radius / rings.length)
      const ringDistanceFromCenter = ringStart + radius / rings.length / 2

      // ==============================
      // Calculate position

      const r = ringDistanceFromCenter
      const angleInDegrees = segmentPos
      const radians = angleInDegrees * (Math.PI / 180)
      const xPos = cx + Math.sin(radians) * r
      const yPos = cy - Math.cos(radians) * r

      return { x: xPos, y: yPos }
    }
  }

  return { x: cx, y: cy }
}

interface GetRingLabelPositionProps {
  cx: number
  cy: number
  numOfRings: number
  numOfSegments: number
  radius: number
  ringIndex: number
}

export const getRingLabelPosition = ({
  cx,
  cy,
  numOfRings,
  numOfSegments,
  radius,
  ringIndex
}: GetRingLabelPositionProps): { x: number; y: number } => {
  const currentSegmentIndex = 0
  const fullCircle = 360

  // Segment
  const segmentStart = currentSegmentIndex
  const segmentEnd = currentSegmentIndex * (fullCircle / numOfSegments) + fullCircle / numOfSegments
  const segmentCenter = segmentStart + (segmentEnd - segmentStart) / 2

  // Ring
  const ringHeight = radius / numOfRings
  const ringStart = (numOfRings - 1 - ringIndex) * ringHeight
  const ringCenter = ringStart + radius / ringHeight
  const ringTop = ringStart + ringHeight - ringHeight / 5

  const r = ringTop
  const angleInDegrees = segmentCenter
  const radians = angleInDegrees * (Math.PI / 180)

  const xPos = cx + Math.sin(radians) * r
  const yPos = cy - Math.cos(radians) * r

  return { x: xPos, y: yPos }
}

export const groupDots = (dots: Dot[], segments: Segment[], segmentValue) => {
  try {
    const groupedDots = dots.reduce((groupArr: DotGroup[], dot: Dot) => {
      const dotSegment = segments.find((s: Segment) => s.id === dot[segmentValue])

      if (dotSegment) {
        if (groupArr.find((g: DotGroup) => g.segmentLabel === dotSegment.label)) {
          // Group for current label already created
          // Map existing groups and add current dot to according group
          return groupArr.map((g: DotGroup) => {
            if (g.segmentLabel === dotSegment.label) {
              return {
                ...g,
                dots: [...g.dots, dot]
              }
            } else {
              return g
            }
          })
        } else {
          // Group not created yet -> Create
          return [...groupArr, { segmentLabel: dotSegment.label, dots: [dot] }]
        }
      } else {
        // Segment not active, dots are not shown
        return groupArr
      }
    }, [])

    if (groupedDots) return groupedDots
    return []
  } catch (error) {
    return []
  }
}
