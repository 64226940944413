import { format, subDays, subMonths } from 'date-fns'

export type Response = {
  dateStart: string
  dateEnd: string
}

// Expects a date filter, e.g. "7 days" or Array with start and end date
const getDateForFilter = (dateFilter): Response => {
  if (Array.isArray(dateFilter)) {
    return {
      dateStart: format(dateFilter[0], 'yyyy-MM-dd'),
      dateEnd: format(dateFilter[1], 'yyyy-MM-dd')
    }
  } else if (dateFilter === 'day' || dateFilter === 'week' || dateFilter === 'month') {
    switch (dateFilter) {
      case 'day': {
        const startDate = format(subDays(new Date(), 1), 'yyyy-MM-dd')
        return { dateStart: startDate, dateEnd: format(new Date(), 'yyyy-MM-dd') }
      }
      case 'week': {
        const startDate = format(subDays(new Date(), 7), 'yyyy-MM-dd')
        return { dateStart: startDate, dateEnd: format(new Date(), 'yyyy-MM-dd') }
      }
      case 'month': {
        const startDate = format(subMonths(new Date(), 1), 'yyyy-MM-dd')
        return { dateStart: startDate, dateEnd: format(new Date(), 'yyyy-MM-dd') }
      }
      default: {
        return null
      }
    }
  } else {
    return { dateStart: null, dateEnd: null }
  }
}

export default getDateForFilter
