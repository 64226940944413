import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// API
import { useRoadmapBuilder, useRoadmapLoader } from '../api'

// Components
import { DateFilter, FilterMenu, YearFilter } from '../components/Filter'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import NoResults from '../components/NoResults'
import Roadmap from '../components/Roadmap'
import Search from '../components/Search'
import TopicFilter from '../components/TopicFilter'

// Store
import { useTopicStore } from '../store'

// Types
import { DateRange, TimePeriod, Type } from '../types'

// ================================================================================================

const RoadmapPage = () => {
  const PAGE_SIZE = 50

  // ============================================
  // Store

  const { activeTopics } = useTopicStore()

  // ============================================
  // State

  // All states prepended by 'filter' are user set filters
  const [filterDateRange, setFilterDateRange] = useState<DateRange | null>(null)
  const [filterYears, setFilterYears] = useState<Array<string>>([])
  const [filterTimePeriod, setFilterTimePeriod] = useState<TimePeriod | null>(null) // quick filter
  // Load more
  const [page, setPage] = useState(1)
  // Page search
  const [search, setSearch] = useState('')
  // Data source
  const [type, setType] = useState<Type>('all')

  // ============================================
  // Handler

  const handleLoadMore = () => {
    setPage(prev => prev + 1)
  }

  const handleYearToggle = (year: string) => {
    setPage(1)

    if (filterYears.find(y => y === year)) {
      setFilterYears(prev => prev.filter(y => y !== year))
    } else {
      setFilterYears([...filterYears, year])
    }
  }

  const handleSearch = (input: string) => {
    setPage(1)
    setSearch(input)
  }

  const handleTabFilter = (filter: Type) => {
    setPage(1)
    setType(filter)
  }

  // ============================================
  // Data

  const { loading: loadingYears, years } = useRoadmapBuilder({
    dateFilter: filterDateRange ? filterDateRange : filterTimePeriod,
    search,
    topics: activeTopics,
    type
  })

  const { count, loading, roadmap } = useRoadmapLoader({
    dateFilter: filterDateRange ? filterDateRange : filterTimePeriod,
    page,
    pageSize: PAGE_SIZE,
    search,
    topics: activeTopics,
    years: filterYears,
    type
  })

  // ============================================
  // Render

  return (
    <Layout>
      <Header>
        <HeaderContainer>
          {/* <Label>Search Term</Label> */}
          <Search onSubmit={handleSearch} showSaveBtn />

          <Label>Trend Topics</Label>
          <TopicFilter />
        </HeaderContainer>
      </Header>

      <Content>
        <FilterWrapper>
          <TabFilter animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
            <Tab active={type === 'all'} onClick={() => handleTabFilter('all')}>
              All
            </Tab>
            <Tab active={type === 'news'} onClick={() => handleTabFilter('news')}>
              News
            </Tab>
            <Tab
              active={type === 'earnings-calls'}
              onClick={() => handleTabFilter('earnings-calls')}
            >
              Earnings Call
            </Tab>
          </TabFilter>
          <FilterMenu>
            <DateFilter
              dateFilter={filterDateRange}
              first
              onReset={() => {
                setPage(1)
                setFilterDateRange(null)
                setFilterTimePeriod(null)
              }}
              onSetDateFilter={(filter: DateRange) => {
                setPage(1)
                setFilterDateRange(filter)
              }}
              onSetTimePeriod={(period: TimePeriod) => {
                setPage(1)
                setFilterTimePeriod(period)
              }}
              timePeriod={filterTimePeriod}
              title="Publication Date"
            />

            <YearFilter
              onToggle={handleYearToggle}
              selected={filterYears}
              years={years ? years : []}
            />
          </FilterMenu>
        </FilterWrapper>

        {(loading && !roadmap.length) || loadingYears ? (
          <LoadingSpinner>
            <Loader color="#102a43" />
          </LoadingSpinner>
        ) : !loading && !loadingYears && roadmap.length === 0 ? (
          <NoResults />
        ) : (
          <Roadmap
            data={roadmap && Array.isArray(roadmap) ? roadmap : []}
            loadingMore={!!(loading && roadmap.length)}
            loadMoreVisible={
              !!(count > 0 && PAGE_SIZE * page < count && (!loading || (loading && roadmap.length)))
            }
            onLoadMore={handleLoadMore}
            years={filterYears && filterYears.length ? filterYears : years}
          />
        )}
      </Content>
    </Layout>
  )
}

export default RoadmapPage

// ================================================================================================

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0 2rem;
  background: ${({ theme }) => theme.surface};
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.03);
`

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  padding: 0 2rem;
  margin: 0 auto;
  flex: 1;
`

const Content = styled.main`
  width: 100%;
  max-width: 1140px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 2rem;
  margin: 0 auto;
`

const FilterWrapper = styled.aside`
  position: sticky;
  top: 3rem;
  flex: 0 0 360px;
  height: 3rem;
  margin: 0 3rem 0 0;
`

const TabFilter = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: none;
`

const Tab = styled.button`
  height: 3rem;
  padding: 0 1.5rem;
  flex: 0.32;
  background: ${({ active, theme }) => (active ? theme.surface : '#f4f6f7')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.text};
  line-height: 1.1;

  &:first-child {
    border-radius: 4px 0 0 0;
  }
  &:last-child {
    border-radius: 0 4px 0 0;
  }
`

const Label = styled.label`
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.light2};
  font-weight: bold;
  margin-bottom: 0.75rem;
`

const LoadingSpinner = styled.div`
  height: 16rem;
  width: 100%;
`
