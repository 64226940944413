import { AnimatePresence, motion } from 'framer-motion'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import 'rc-slider/assets/index.css'
import { Range } from 'rc-slider'
import FilterHeader from './FilterHeader'
import { getDefaultStartValue, percentToDate } from './utils'
import { DateRange, TimePeriod } from '../../types'
import { subMonths } from 'date-fns'

// ================================================================================================

interface Props {
  accordion?: boolean
  first?: boolean
  dateFilter?: DateRange
  dateRange?: DateRange
  onReset: () => void
  onSetDateFilter: (filter: DateRange) => void
  onSetTimePeriod: (period: TimePeriod) => void
  timePeriod?: TimePeriod
  title?: string
}

const DateFilter: FC<Props> = ({
  accordion = true,
  first,
  dateFilter,
  dateRange,
  onReset,
  onSetDateFilter,
  onSetTimePeriod,
  timePeriod,
  title = 'Date'
}) => {
  const [open, setOpen] = useState(false)

  const defaultStartValue = getDefaultStartValue({
    dateFilter: dateFilter,
    dateRangeStart: dateRange ? dateRange[0] : null,
    dateRangeEnd: dateRange ? dateRange[1] : null
  })
  const defaultEndValue = 100

  // State
  // Date range slider values as percent
  const [startValue, setStartValue] = useState(defaultStartValue)
  const [endValue, setEndValue] = useState(defaultEndValue)
  const [inputStart, setInputStart] = useState<string | null>(null)
  const [inputEnd, setInputEnd] = useState<string | null>(null)
  const [submitEnabled, setSubmitEnabled] = useState(false)

  // Effect
  useEffect(() => {
    if (
      startValue !== defaultStartValue ||
      endValue !== defaultEndValue ||
      inputStart ||
      inputEnd
    ) {
      setSubmitEnabled(true)
    }
  }, [inputStart, inputEnd, startValue, endValue])

  // Handler
  const handleReset = () => {
    setInputStart('')
    setInputEnd('')
    setStartValue(0)
    setEndValue(defaultEndValue)
    onReset()
  }

  const handleSubmitDateFilter = () => {
    if (submitEnabled) {
      setSubmitEnabled(false)

      if (inputStart || inputEnd) {
        const startDateInMs = inputStart
          ? new Date(inputStart).getTime()
          : subMonths(new Date(), 29).getTime()
        const endDateInMs = inputEnd ? new Date(inputEnd).getTime() : new Date().getTime()

        onSetDateFilter([startDateInMs, endDateInMs])
      } else {
        const startDateInMs = percentToDate(dateRange[0], dateRange[1], startValue, null, true)
        const endDateInMs = percentToDate(dateRange[0], dateRange[1], endValue, null, true)

        // @ts-ignore
        onSetDateFilter([startDateInMs, endDateInMs])
      }
    }
  }

  return accordion ? (
    <>
      <FilterHeader
        first={first}
        onToggleOpen={() => setOpen(prev => !prev)}
        open={open}
        title={title}
      />

      <AnimatePresence initial={false}>
        {open && (
          <Content
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: dateRange ? 340 : 260 },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <QuickSelectLabel>Quick Select</QuickSelectLabel>
            <QuickSelects>
              <QuickSelect active={timePeriod === 'day'} onClick={() => onSetTimePeriod('day')}>
                24 hours
              </QuickSelect>
              <QuickSelect active={timePeriod === 'week'} onClick={() => onSetTimePeriod('week')}>
                7 days
              </QuickSelect>
              <QuickSelect active={timePeriod === 'month'} onClick={() => onSetTimePeriod('month')}>
                30 days
              </QuickSelect>
            </QuickSelects>

            {dateRange && (
              <SliderLabel>{`${percentToDate(
                dateRange[0],
                dateRange[1],
                startValue
              )} - ${percentToDate(dateRange[0], dateRange[1], endValue)}`}</SliderLabel>
            )}

            {dateRange && (
              <Slider
                allowCross={false}
                onChange={(range: number[]) => {
                  setStartValue(range[0])
                  setEndValue(range[1])
                }}
                value={[startValue, endValue]}
              />
            )}

            <QuickSelectLabel>Exact date range</QuickSelectLabel>

            <Inputs>
              <Input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault()
                  setInputStart(e.currentTarget.value)
                }}
                placeholder="YYYY-MM-DD"
                value={inputStart}
              />
              <span>to</span>
              <Input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault()
                  setInputEnd(e.currentTarget.value)
                }}
                placeholder="YYYY-MM-DD"
                value={inputEnd}
              />
            </Inputs>

            <Footer style={{ marginTop: '2rem' }}>
              <CTA active={submitEnabled} onClick={handleSubmitDateFilter}>
                Apply
              </CTA>
              {(defaultStartValue !== startValue ||
                defaultEndValue !== endValue ||
                timePeriod === 'day' ||
                timePeriod === 'month' ||
                timePeriod === 'week' ||
                inputStart ||
                inputEnd) && (
                <Reset
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  onClick={handleReset}
                  transition={{ duration: 0.15, type: 'tween' }}
                >
                  Reset
                </Reset>
              )}
            </Footer>
          </Content>
        )}
      </AnimatePresence>
    </>
  ) : (
    <Content>
      <QuickSelectLabel>Quick Select</QuickSelectLabel>
      <QuickSelects>
        <QuickSelect active={timePeriod === 'day'} onClick={() => onSetTimePeriod('day')}>
          24 hours
        </QuickSelect>
        <QuickSelect active={timePeriod === 'week'} onClick={() => onSetTimePeriod('week')}>
          7 days
        </QuickSelect>
        <QuickSelect active={timePeriod === 'month'} onClick={() => onSetTimePeriod('month')}>
          30 days
        </QuickSelect>
      </QuickSelects>

      {dateRange && (
        <SliderLabel>{`${percentToDate(dateRange[0], dateRange[1], startValue)} - ${percentToDate(
          dateRange[0],
          dateRange[1],
          endValue
        )}`}</SliderLabel>
      )}

      {dateRange && (
        <Slider
          allowCross={false}
          onChange={(range: number[]) => {
            setStartValue(range[0])
            setEndValue(range[1])
          }}
          value={[startValue, endValue]}
        />
      )}

      <QuickSelectLabel>Exact date range</QuickSelectLabel>

      <Inputs>
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault()
            setInputStart(e.currentTarget.value)
          }}
          placeholder="YYYY-MM-DD"
          value={inputStart}
        />
        <span>to</span>
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault()
            setInputEnd(e.currentTarget.value)
          }}
          placeholder="YYYY-MM-DD"
          value={inputEnd}
        />
      </Inputs>

      <Footer style={{ marginTop: '2rem' }}>
        <CTA active={submitEnabled} onClick={handleSubmitDateFilter}>
          Apply
        </CTA>
        {(defaultStartValue !== startValue ||
          defaultEndValue !== endValue ||
          timePeriod === 'day' ||
          timePeriod === 'month' ||
          timePeriod === 'week' ||
          inputStart ||
          inputEnd) && (
          <Reset
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            onClick={handleReset}
            transition={{ duration: 0.15, type: 'tween' }}
          >
            Reset
          </Reset>
        )}
      </Footer>
    </Content>
  )
}

export default DateFilter

// ================================================================================================

const Content = styled(motion.div)`
  transform-origin: top center;
  width: 100%;
  padding: 0 0 0 0;
`

const QuickSelectLabel = styled.span`
  display: block;
  font-size: 0.75rem;
  margin: 1rem 0 0.625rem 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #babcbf;
`

const QuickSelects = styled.div`
  display: flex;
  align-items: center;
`

const QuickSelect = styled.button`
  height: 36px;
  padding: 0 1rem;
  background-color: ${({ active }) => (active ? '#122a43' : '#eef1f4')};
  color: ${({ active, theme }) => (active ? '#fff' : '#ADB5BD')};
  font-size: 0.8125rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  border-radius: 2px;

  &:hover {
    background: #122a43;
    color: #fff;
  }
`

const SliderLabel = styled.span`
  display: block;
  font-size: 0.75rem;
  margin: 2rem 0 1rem 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #babcbf;
`

const Slider = styled(Range)`
  width: calc(100% - 16px);
  margin: 0 0 2rem 8px;

  .rc-slider-rail {
    background-color: ${({ theme }) => theme.base};
  }

  .rc-slider-track {
    background-color: #122a43;
  }

  .rc-slider-handle {
    width: 16px;
    height: 16px;
    margin-top: -6px;
    box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.1);
    border: ${({ theme }) => `4px solid #fff`};
    background-color: #122a43;

    &:focus,
    &:hover {
      border: ${({ theme }) => `4px solid #fff`};
      background-color: #122a43;
      outline: none;
      box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.1);
    }
  }
`

const Inputs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${({ theme }) => theme.text};
    font-size: 0.8125rem;
  }
`

const Input = styled.input`
  background: ${({ theme }) => theme.base};
  padding: 0 0.75rem;
  height: 36px;
  border: none;
  width: 110px;
  text-align: center;
  border-radius: 2px;
  color: ${({ theme }) => theme.text};
  font-size: 0.8125rem;
  font-weight: 500;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #adb5bd;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #adb5bd;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #adb5bd;
  }

  &:focus {
    outline: none;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

interface CTAProps {
  active?: boolean
}
const CTA = styled.button<CTAProps>`
  height: 36px;
  padding: 0 1rem;
  background-color: ${({ active }) => (active ? '#122a43' : '#EEF1F4')};
  color: ${({ active }) => (active ? '#fff' : '#ADB5BD')};
  font-size: 0.8125rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  cursor: ${({ active }) => (active === true ? 'pointer' : 'not-allowed')};
`

const Reset = styled(motion.button)`
  padding: 0 1rem;
  margin-left: 0.5rem;
  color: #babcbf;
  font-size: 0.8125rem;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
`
