import * as Parse from 'parse'

type Response = Promise<{ error?: Error | null; rating?: any }>

const getRating = async (email: string): Response => {
  try {
    const RatingObject = Parse.Object.extend('Rating')
    const ratingRequest = new Parse.Query(RatingObject)

    ratingRequest.equalTo('user', email)

    const results = await ratingRequest.find()

    return { rating: results.map(item => item.get('topic')) }
  } catch (error) {
    return { error }
  }
}

export default getRating
