import { useCallback } from 'react'
import useAsyncLoader from './useAsyncLoader'
import * as Parse from 'parse'

// Types
import { Bit, DateRange, TimePeriod, Topic } from '../types'

// Utils
import { getDateForFilter } from '../utils/date'
import { logError } from '../utils/error'

// ================================================================================================

interface Props {
  dateFilter?: DateRange | TimePeriod
  search?: string
  topics?: Array<Topic>
  type?: string
}

interface Response {
  error?: Error
  loading?: boolean
  years: Array<string>
}

const useRoadmapBuilder = ({ dateFilter, search, topics, type }: Props): Response => {
  const { data: roadmap, error, loading } = useAsyncLoader(
    // @ts-ignore
    useCallback(() => {
      let dStart = null
      let dEnd = null

      if (dateFilter) {
        const { dateStart, dateEnd } = getDateForFilter(dateFilter)

        if (dateStart) {
          try {
            const dateStartInMS = new Date(dateStart).getTime()
            dStart = dateStartInMS
          } catch (error) {
            logError(error)
          }
        }

        if (dateEnd) {
          try {
            const dateEndInMS = new Date(dateEnd).getTime()
            dEnd = dateEndInMS
          } catch (error) {
            logError(error)
          }
        }
      }

      return Parse.Cloud.run('bitYears', {
        dateStart: dStart,
        dateEnd: dEnd,
        search,
        topics: topics && topics.length ? topics.map((t: Topic) => t.identifier) : null,
        type
      }).then(data => data)
    }, [dateFilter, search, topics, type])
  )

  return {
    error,
    loading,
    years:
      Array.isArray(roadmap) && roadmap[0] && typeof roadmap[0] === 'string'
        ? roadmap.sort((a, b) => parseInt(a) - parseInt(b))
        : []
  }
}

export default useRoadmapBuilder
