import React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useComponentSize from '@rehooks/component-size'
import { AnimatePresence, motion } from 'framer-motion'

// Partials
import Dot from './Dot'
import RadarContext from './RadarContext'
import Rating from './Rating'

// Types
import { Dot as DotType, Ring as RingType, Segment as SegmentType, Color } from './types'

// Utils
import { groupDots, getDotPosition, getRingLabelPosition } from './utils'
import { getExcerpt } from '../../utils/string'

// ================================================================================================

const R: React.FC = () => {
  // Ref
  const radarEl = useRef(null)
  let { width: radarWidth } = useComponentSize(radarEl)

  // Context
  const {
    colorDiff,
    colors,
    colorsSelection,
    cutout,
    dotPositions,
    dots: allDots,
    hovering,
    rings: allRings,
    ringsSelection,
    setCx,
    setCy,
    setRadius,
    segments: allSegements,
    segmentsSelection
  } = useContext(RadarContext)

  // Transformation
  const segments = allSegements.filter((s: SegmentType) => s.active)
  // Building order is outside to inside, so tings are reversed
  const rings = allRings.filter((r: RingType) => r.active).reverse()

  const ringValue = ringsSelection && ringsSelection.value ? ringsSelection.value : ''
  const segmentValue = segmentsSelection && segmentsSelection.value ? segmentsSelection.value : ''

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 150)
  }, [])

  // ============================================

  // Konstants
  const CANVAS = radarWidth
  const DIAMETER = radarWidth - radarWidth / 5 // padding on each side
  const RADIUS = DIAMETER / 2
  const CX = CANVAS / 2
  const CY = CANVAS / 2

  const cutoutAdd = cutout ? 180 + cutout / 2 : 0 // change start pos if piece of circle is cut out
  const fullCircle = cutout ? 360 - cutout : 360 // reduce full radius if cutout

  const outerRing = RADIUS / 6
  const outerRingMargin = RADIUS / 90

  // Set values globally, needed for filter hover
  setCx(CX)
  setCy(CY)
  setRadius(RADIUS)

  // Animation
  const child = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  // ============================================

  const getDotColor = (dot: DotType) => {
    if (!colorDiff) return '#14919B'

    const key = colorsSelection && colorsSelection.value
    const dotValue = dot[key]

    if (key && dotValue) {
      const colorItem = colors.find(c => c.value && c.value === dotValue)
      const colorCode = colorItem && colorItem.code ? colorItem.code : ''
      if (colorCode) return colorCode
    }

    return '#243B53'
  }

  // ============================================

  return (
    <Radar id="Radar" ref={radarEl}>
      <RadarSvg height={CANVAS} width={CANVAS}>
        <OuterRing
          cx={CX}
          cy={CY}
          r={RADIUS + outerRing / 12 + outerRingMargin}
          // stroke="#C6CAD0"
          stroke="#d4dae0" // Frank
          strokeWidth={outerRing}
          // stroke="url('#radial')"
        />

        <RadarArea cx={CX} cy={CY} r={RADIUS} />

        {rings && (
          <defs>
            {rings.map((ring: RingType, i: number) => {
              const size = ((DIAMETER / rings.length) * (rings.length - i)) / 2

              return (
                <path
                  d={`
                M ${CX} ${CY}
                m -${size}, 0
                a ${size},${size} 0 1,0 ${size * 2},0
                a ${size},${size} 0 1,0 -${size * 2},0
              `}
                  id={`curve-${i}`}
                  key={`ring-${i}`}
                />
              )
            })}

            <radialGradient id="radial">
              <stop offset="98%" stopColor="#fff" />
              <stop offset="100%" stopColor="#D2D7DC" />
            </radialGradient>
          </defs>
        )}

        <AnimatePresence>
          {!loading && (
            <Fade
              animate={{
                opacity: 1
              }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              {rings &&
                rings.map((ring: RingType, i: number) => {
                  const size = (DIAMETER / rings.length) * (rings.length - i)
                  const r = 234
                  const g = 236
                  const b = 238

                  return (
                    <Ring
                      ccR={r + i * 4}
                      ccG={g + i * 4}
                      ccB={b + i * 4}
                      cx={CX}
                      cy={CY}
                      id={`radar-ring-${i}`}
                      key={`ring-${i}`}
                      r={size / 2}
                    />
                  )
                })}

              {segments &&
                segments.map((segment: SegmentType, i: number) => {
                  let angleInDegrees = (fullCircle / segments.length) * i + cutoutAdd

                  const radians = angleInDegrees * (Math.PI / 180)
                  const x2 = CX + Math.sin(radians) * (RADIUS + outerRing / 1 + outerRingMargin)
                  const y2 = CY - Math.cos(radians) * (RADIUS + outerRing / 1 + outerRingMargin)

                  return <SegementLine key={`segment-${i}`} x1={CX} x2={x2} y1={CY} y2={y2} />
                })}

              {allDots &&
                allDots.map((dot: DotType) => {
                  const currentSegmentIndex = segments.findIndex(
                    (s: SegmentType) => s.id === dot[segmentValue]
                  )

                  if (currentSegmentIndex >= 0) {
                    const { x, y } = getDotPosition(
                      RADIUS,
                      CX,
                      CY,
                      dot,
                      allDots,
                      rings,
                      segments,
                      ringValue,
                      segmentValue,
                      cutout
                    )

                    if (x && y) {
                      return (
                        <Dot
                          color={getDotColor(dot)}
                          data={dot}
                          trending={dot.trending}
                          x={x}
                          y={y}
                        />
                      )
                    }
                  }
                })}

              {segments &&
                segments.map((segment: SegmentType, i: number) => {
                  const r = RADIUS + radarWidth / 48 // distance from radar
                  const angleInDegrees =
                    (fullCircle / segments.length) * i +
                    fullCircle / segments.length / 2 +
                    cutoutAdd
                  const radians = angleInDegrees * (Math.PI / 180)

                  let rotation = 0
                  let xPos = CX + Math.sin(radians) * r
                  let yPos = CY - Math.cos(radians) * r

                  // Get label quarter: right top | right bottom | left bottom | left top
                  if (angleInDegrees >= 0 && angleInDegrees < 90) {
                    // Right top
                    xPos = CX + Math.sin(radians) * r
                    yPos = CY - Math.cos(radians) * r
                    rotation = angleInDegrees
                  } else if (angleInDegrees >= 90 && angleInDegrees < 180) {
                    // Right bottom
                    xPos = CX + Math.sin(radians) * r
                    yPos = CY - Math.cos(radians) * r
                    rotation = angleInDegrees - 180
                  } else if (angleInDegrees >= 180 && angleInDegrees < 270) {
                    // Left bottom
                    xPos = CX + Math.sin(radians) * r
                    yPos = CY - Math.cos(radians) * r
                    rotation = angleInDegrees - 180
                  } else {
                    // Left top
                    xPos = CX + Math.sin(radians) * r
                    yPos = CY - Math.cos(radians) * r
                    rotation = angleInDegrees - 360
                  }

                  return (
                    <SegmentLabel
                      transform={`rotate(${rotation} ${xPos} ${yPos})`} // dont put this in style
                      x={xPos}
                      y={yPos}
                    >
                      {segment.label}
                    </SegmentLabel>
                  )
                })}

              {rings &&
                rings.map((segment: SegmentType, i: number) => {
                  const { x, y } = getRingLabelPosition({
                    cx: CX,
                    cy: CY,
                    numOfRings: rings.length,
                    numOfSegments: segments.length,
                    radius: RADIUS,
                    ringIndex: i
                  })

                  let rotation = fullCircle / segments.length / 2

                  return (
                    <RingLabel
                      transform={`rotate(${rotation} ${x} ${y})`} // dont put this in style
                      x={x}
                      y={y}
                    >
                      {segment.label}
                      {/* <textPath startOffset={x + y} xlinkHref={`#curve-${i}`}>
                      </textPath> */}
                    </RingLabel>
                  )
                })}

              <Center
                cx={CX}
                cy={CY}
                height={RADIUS / 10}
                r={RADIUS / 10 / 2}
                variants={child}
                width={RADIUS / 10}
              />
            </Fade>
          )}
        </AnimatePresence>

        {/* {cutout && <Cutout cx={CX} cy={CY} r={RADIUS} />} */}
      </RadarSvg>

      <AnimatePresence>
        {hovering && hovering.dot && ((hovering.x && hovering.y) || dotPositions) && (
          <Popup
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            showBelow={
              hovering.y
                ? hovering.y <= RADIUS
                : dotPositions.find(pos => pos.id === hovering.id).y <= RADIUS
            }
            transition={{ duration: 0.25, type: 'tween' }}
            x={hovering.x ? hovering.x : dotPositions.find(pos => pos.id === hovering.id).x}
            y={hovering.y ? hovering.y : dotPositions.find(pos => pos.id === hovering.id).y}
          >
            <img
              src={
                hovering.dot.image
                  ? hovering.dot.image
                  : hovering.dot.imageUrl
                  ? hovering.dot.imageUrl
                  : ''
              }
            />
            <h2>{hovering.dot.title}</h2>
            <p>{getExcerpt(hovering.dot.description, 100)}</p>

            <svg className="tip" viewBox="0 0 40 10" xmlns="http://www.w3.org/2000/svg">
              <path d="M40 0H0l18.437 9.114a4 4 0 003.578-.017L40 0z" fill="#fff" />
            </svg>
          </Popup>
        )}
      </AnimatePresence>
    </Radar>
  )
}

// ================================================================================================

const Radar = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
`

const RadarSvg = styled.svg`
  fill: none;
  position: relative;
  width: 100%;
  min-height: calc(67vh);
`

const OuterRing = styled.circle``

const RadarArea = styled.circle`
  stroke: #fff;
  strokewidth: 0;
`

const Fade = styled(motion.g)``

interface RingProps {
  ccR?: number
  ccG?: number
  ccB?: number
}
const Ring = styled.circle<RingProps>`
  fill: #f3f6f9;
  fill: ${({ ccR, ccG, ccB }) => `rgba(${ccR}, ${ccG}, ${ccB})`};
  stroke: #fff;
  strokewidth: 4;
`

const Center = styled(motion.circle)`
  fill: #122a43;
`

const SegementLine = styled.line`
  stroke: #fff;
  strokewidth: 4;
`

const RingLabel = styled.text`
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  font-size: 9px;
  font-size: 0.4375vw;
  font-weight: 700;
  fill: rgba(34, 58, 82, 0.4);
  text-transform: uppercase;
  text-anchor: middle;
  dominant-baseline: hanging;

  textPath {
    font-size: 9px;
  }
`

const SegmentLabel = styled.text`
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  font-size: 11px;
  font-size: 0.625vw;
  font-weight: 700;
  fill: #122a43;
  fill: ${({ theme }) => theme.text};
  fill: rgba(255, 255, 255, 0.9);
  fill: #525961; /* Frank */
  text-transform: uppercase;
  text-anchor: middle;
  alignment-baseline: middle;
`

const Cutout = styled.path`
  fill: #fff;
`

interface PopupProps {
  showBelow?: boolean
  x: number
  y: number
}
const Popup = styled(motion.div)<PopupProps>`
  box-sizing: border-box;
  position: absolute;
  top: ${({ showBelow, y }) => (showBelow ? `${y + 34}px` : `${y - 284}px`)};
  left: ${({ x }) => `${x - 90}px`};
  width: 180px;
  height: 250px;
  padding: 1rem 1.25rem;
  background: #fff;
  border-radius: 2px;
  z-index: 10;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.04);

  img {
    width: 100%;
    height: 90px;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 0.8125rem;
    font-weight: bold;
    margin: 0 0 0.625rem;
    color: ${({ theme }) => theme.text};
  }

  .tip {
    position: absolute;
    bottom: ${({ showBelow }) => (showBelow ? 'initial' : '-10px')};
    top: ${({ showBelow }) => (showBelow ? '-10px' : 'initial')};
    transform: ${({ showBelow }) => (showBelow ? 'rotate(180deg)' : 'none')};
    transform-origin: center;
    left: 0;
    width: 100%;
    height: 10px;
  }

  p {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.text};
  }
`

// ================================================================================================

export default R
