import React from 'react'
import { Dot, DotPosition, Option } from './types'

// ================================================================================================

const RadarContext = React.createContext({
  activeDots: [],
  addDotPosition: (position: DotPosition) => {}, // dot coordinates globally saved for popup
  colorDiff: true,
  colorRange: [],
  colors: [],
  colorsSelection: null,
  cutout: 0,
  cx: 0,
  cy: 0,
  dotHighlighting: true,
  dotPositions: null, // Positions x,y for hover from outside radar (filter)
  dots: [],
  groupDotsBySegment: false,
  handleHover: (dot?: Dot, x?: number, y?: number) => {},
  handleToggle: (type: 'dot' | 'ring' | 'segment', id: string) => {},
  handleAddActiveDot: (dot: Dot) => {},
  handleRemoveActiveDot: (dot: Dot) => {},
  hovering: null, // currently hovered dot
  radius: 0,
  rings: [],
  ringsSelection: null,
  segments: [],
  segmentsSelection: null,
  selectOptions: [],
  setActiveDots: (dots: Dot[]) => {},
  setColorDiff: (colorDiff: boolean) => {},
  setCx: cx => {},
  setCy: cy => {},
  setColorsSelection: (selection: Option) => {},
  setDotHighlighting: (dotHighlighting: boolean) => {},
  setRadius: r => {},
  setRingsSelection: (selection: Option) => {},
  setSegmentsSelection: (selection: Option) => {},
  singleColoredDots: false
})

// ================================================================================================

export default RadarContext
