import React, { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Assets
import collapseImg from '../assets/img/collapse.jpg'
import disciplineImg from '../assets/img/discipline.jpg'
import growthImg from '../assets/img/growth.jpg'
import transformationalImg from '../assets/img/transformational.jpg'

// Components
import Loader from '../components/Loader'

// ================================================================================================

const COLORS = ['#243B53', '#102A43', '#334E68', '#486581']

const animate = { opacity: 1 }
const initial = { opacity: 0 }
const transition = { delay: 0.1, duration: 0.33, type: 'tween' }

interface Props {
  collapse: number
  continuedGrowth: number
  discipline: number
  transformational: number
}

const ScenarioChart: FC<Props> = ({ collapse, continuedGrowth, discipline, transformational }) => {
  const getSize = (value: number) => {
    const result = (value * 75) / 25
    return result > 100 ? 100 : result < 33 ? 33 : result
  }

  return (
    <Wrapper>
      {collapse && continuedGrowth && discipline && transformational ? (
        <>
          <Box i={0}>
            <ChartWrapper>
              <Chart
                animate={animate}
                initial={initial}
                transition={transition}
                i={0}
                img={growthImg}
                left
                size={getSize(continuedGrowth)}
                top
              >
                <span>{continuedGrowth.toFixed(0)}%</span>
                <div>
                  <div>
                    <h3>Continued Growth</h3>
                  </div>
                  {/* <svg
                    style={{ transform: 'rotate(22.5deg)' }}
                    viewBox="0 0 128 128"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M91.088 47.1l-60.494 60.494-10.607-10.607 60.13-60.13-18.797-.97.772-14.98 35.342 1.822a8 8 0 017.571 7.463l2.369 35.896-14.968.987L91.088 47.1z" />
                  </svg> */}
                </div>
              </Chart>
            </ChartWrapper>
          </Box>
          <Box i={1}>
            <ChartWrapper>
              <Chart
                animate={animate}
                initial={initial}
                transition={{ ...transition, delay: 0.2 }}
                i={1}
                img={transformationalImg}
                right
                size={getSize(transformational)}
                top
              >
                <span>{transformational.toFixed(0)}%</span>
                <div>
                  <div>
                    <h3>Transformational</h3>
                  </div>
                  {/* <svg
                    style={{ transform: 'rotate(-22.5deg)' }}
                    viewBox="0 0 128 128"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M91.088 47.1l-60.494 60.494-10.607-10.607 60.13-60.13-18.797-.97.772-14.98 35.342 1.822a8 8 0 017.571 7.463l2.369 35.896-14.968.987L91.088 47.1z" />
                  </svg> */}
                </div>
              </Chart>
            </ChartWrapper>
          </Box>
          <Box i={2}>
            <ChartWrapper>
              <Chart
                animate={animate}
                initial={initial}
                transition={{ ...transition, delay: 0.3 }}
                bottom
                i={2}
                img={disciplineImg}
                left
                size={getSize(discipline)}
              >
                <span>{discipline.toFixed(0)}%</span>

                <div>
                  <div>
                    <h3>Sustainable Impact</h3>
                  </div>
                  {/* <svg
                    style={{ transform: 'rotate(67.5deg)' }}
                    viewBox="0 0 128 128"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M91.088 47.1l-60.494 60.494-10.607-10.607 60.13-60.13-18.797-.97.772-14.98 35.342 1.822a8 8 0 017.571 7.463l2.369 35.896-14.968.987L91.088 47.1z" />
                  </svg> */}
                </div>
              </Chart>
            </ChartWrapper>
          </Box>
          <Box i={3}>
            <ChartWrapper>
              <Chart
                animate={animate}
                initial={initial}
                transition={{ ...transition, delay: 0.4 }}
                bottom
                i={3}
                img={collapseImg}
                right
                size={getSize(collapse)}
              >
                <span>{collapse.toFixed(0)}%</span>
                <div>
                  <div>
                    <h3>Collapse</h3>
                  </div>
                  {/* <svg
                    style={{ transform: 'rotate(112.5deg)' }}
                    viewBox="0 0 128 128"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M91.088 47.1l-60.494 60.494-10.607-10.607 60.13-60.13-18.797-.97.772-14.98 35.342 1.822a8 8 0 017.571 7.463l2.369 35.896-14.968.987L91.088 47.1z" />
                  </svg> */}
                </div>
              </Chart>
            </ChartWrapper>
          </Box>

          <Triangle position="top" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.763 1.797c-1.58-2.396-5.096-2.396-6.677 0L3.517 87.534c-1.754 2.66.153 6.203 3.34 6.203h113.136c3.186 0 5.094-3.544 3.339-6.203L66.763 1.797z" />
          </Triangle>
          <Triangle position="right" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path d="M126.203 66.914c2.396-1.58 2.396-5.096 0-6.678L40.466 3.669c-2.66-1.755-6.203.153-6.203 3.339v113.137c0 3.186 3.544 5.093 6.203 3.339l85.737-56.569z" />
          </Triangle>
          <Triangle position="bottom" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path d="M60.236 126.203c1.582 2.396 5.097 2.396 6.678 0l56.569-85.737c1.754-2.66-.153-6.203-3.339-6.203H7.007c-3.186 0-5.094 3.544-3.34 6.203l56.57 85.737z" />
            </g>
            <defs>
              <clipPath id="clip0">
                <path d="M0 0h128v128H0z" />
              </clipPath>
            </defs>
          </Triangle>
          <Triangle position="left" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.797 60.236c-2.396 1.582-2.396 5.097 0 6.678l85.737 56.569c2.66 1.754 6.203-.153 6.203-3.339V7.007c0-3.186-3.544-5.094-6.203-3.34L1.797 60.238z" />
          </Triangle>
        </>
      ) : (
        <Loader size={60} />
      )}
    </Wrapper>
  )
}

export default ScenarioChart

// ================================================================================================

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 67vh;
  width: 100%;
`

interface BoxProps {
  i: number
}
const Box = styled.div<BoxProps>`
  flex-grow: 0;
  flex-shrink: 0;
  height: ${({}) => `50%`};
  width: ${({}) => `50%`};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${({ i }) => (i === 2 || i === 3 ? '0.75rem' : '0')};
  padding-right: ${({ i }) => (i % 2 === 0 ? '0.75rem' : '0')};
  padding-bottom: ${({ i }) => (i === 0 || i === 1 ? '0.75rem' : '0')};
  padding-left: ${({ i }) => (i % 2 !== 0 ? '0.75rem' : '0')};

  border-top: ${({ i, theme }) => (i === 2 || i === 3 ? `2px solid ${theme.base}` : 'none')};
  border-right: ${({ i, theme }) => (i % 2 === 0 ? `2px solid ${theme.base}` : 'none')};
  border-bottom: ${({ i, theme }) => (i === 0 || i === 1 ? `2px solid ${theme.base}` : 'none')};
  border-left: ${({ i, theme }) => (i % 2 !== 0 ? `2px solid ${theme.base}` : 'none')};
`

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

interface ChartProps {
  bottom?: boolean
  i: number
  img: string
  left?: boolean
  right?: boolean
  size: number
  top?: boolean
}
const Chart = styled(motion.div)<ChartProps>`
  position: absolute;
  bottom: ${({ top }) => (top ? '0' : 'initial')};
  left: ${({ right }) => (right ? '0' : 'initial')};
  right: ${({ left }) => (left ? '0' : 'initial')};
  top: ${({ bottom }) => (bottom ? '0' : 'initial')};
  background-color: ${({ i }) => COLORS[i]};
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  background-position: center;
  border-radius: 2px;
  height: ${({ size }) => `${size}%`};
  width: ${({ size }) => `${size}%`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
    font-weight: 700;
    font-size: 2.5rem;
    font-size: ${({ size }) => `${size / 10}vh`};
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.25rem;
    margin: 0.75vh 0 0;
    width: 100%;

    > div {
      /* flex: 0; */
    }
  }

  h3 {
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-size: 0.75vw;
    margin-right: 0.375rem;
    text-align: right;
  }

  svg {
    fill: #fff;
    width: 0.875rem;
    height: 0.875rem;
    width: 0.9375vw;
    height: 0.9375vw;
    transform-origin: center;
  }
`

interface TriangleProps {
  position: 'top' | 'right' | 'bottom' | 'left'
}
const Triangle = styled.svg<TriangleProps>`
  position: absolute;
  top: ${({ position }) => (position === 'top' ? '0' : position === 'bottom' ? 'initial' : '50%')};
  right: ${({ position }) =>
    position === 'right' ? '0' : position === 'left' ? 'initial' : '50%'};
  bottom: ${({ position }) =>
    position === 'bottom' ? '0' : position === 'top' ? 'initial' : '50%'};
  left: ${({ position }) => (position === 'left' ? '0' : position === 'right' ? 'initial' : '50%')};
  transform: ${({ position }) =>
    position === 'top' || position === 'bottom' ? 'translate(-50%, 0)' : 'translate(0, -50%)'};
  height: 0.75rem;
  width: 0.75rem;
  fill: ${({ theme }) => theme.base};
`
