import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useHover } from 'react-use'
import styled, { css, keyframes } from 'styled-components'
import { Dot as DotType } from './types'
import RadarContext from './RadarContext'

// ================================================================================================

interface Props {
  color?: string
  data: DotType
  trending?: boolean
  x: number
  y: number
}

const Dot: React.FC<Props> = ({ color, data, trending, x, y }) => {
  const {
    dotHighlighting,
    handleHover,
    handleAddActiveDot,
    hovering,
    ringsSelection,
    singleColoredDots,
    segmentsSelection
  } = useContext(RadarContext)
  const [init, setInit] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setInit(true)
    }, 200)
  }, [])

  const [hoverable, hovered] = useHover(hovered => (
    <Wrapper
      onClick={() => handleAddActiveDot(data)}
      style={{ transformOrigin: `${x}px ${y}px` }}
      trending={dotHighlighting && trending}
    >
      <Circle
        cx={x}
        cy={y}
        color={color}
        init={init}
        otherDotHovered={hovering && hovering.dot && hovering.dot.id !== data.id}
        r={8}
        singleColoredDots={singleColoredDots}
        strokeWidth={9}
        trending={dotHighlighting && trending}
      />
    </Wrapper>
  ))

  useEffect(() => {
    if (hovered) {
      handleHover(data, x, y)
    } else {
      handleHover()
    }
  }, [hovered])

  return hoverable
}

// ================================================================================================

const Wrapper = styled.g`
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  z-index: 9;

  animation: ${({ trending }) =>
    trending
      ? css`
          ${zoom} 2s linear infinite
        `
      : 'none'};

  &:hover {
    transform: ${({ trending }) => (trending ? 'none' : 'scale(1.2)')};
    z-index: 10;
  }
`

const zoom = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.0);
  }
`

const Circle = styled.circle`
  :root {
    --color: 240, 240, 240;
  }

  fill: ${({ color, otherDotHovered, singleColoredDots, trending }) =>
    otherDotHovered
      ? 'rgba(36, 59, 83, 0.15)'
      : trending
      ? '#D12779'
      : singleColoredDots
      ? 'rgba(36, 59, 83, 1)'
      : color
      ? color
      : 'rgba(36, 59, 83, 1)'};
  stroke: ${({ color, otherDotHovered, singleColoredDots, trending }) =>
    otherDotHovered
      ? 'rgba(36, 59, 83, 0.15)'
      : trending
      ? '#D12779'
      : singleColoredDots
      ? 'rgba(36, 59, 83, 1)'
      : color
      ? color
      : 'rgba(36, 59, 83, 1)'};
  stroke-opacity: 0.15;
  cursor: pointer;
  transition: ${({ init }) => (init ? '0.2s' : '0')};
`

// ================================================================================================

export default Dot
