import React from 'react'
import styled from 'styled-components'
import * as Parse from 'parse'
import { motion } from 'framer-motion'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { logError } from '../utils/error'

// Store
import { useUserStore } from '../store'

// ================================================================================================

const Nav: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { userIsAdmin, userIsExternal } = useUserStore()

  const handleSignOut = async () => {
    try {
      await Parse.User.logOut()
    } catch (error) {
      logError(error)
    }
    history.push('/')
  }

  return (
    <Wrapper animate={false} initial={false} transition={false}>
      <Logo>
        <svg viewBox="0 0 500 264" fill="none">
          <path
            d="M161.598 158.047H62.1055V260H0.40625V4.0625H171.617V51.6992H62.1055V110.586H161.598V158.047ZM263.023 260H201.5V4.0625H263.023V260ZM437.926 191.973C437.926 182.949 434.703 175.918 428.258 170.879C421.93 165.84 410.738 160.625 394.684 155.234C378.629 149.844 365.504 144.629 355.309 139.59C322.145 123.301 305.562 100.918 305.562 72.4414C305.562 58.2617 309.664 45.7812 317.867 35C326.188 24.1016 337.906 15.6641 353.023 9.6875C368.141 3.59375 385.133 0.546875 404 0.546875C422.398 0.546875 438.863 3.82813 453.395 10.3906C468.043 16.9531 479.41 26.3281 487.496 38.5156C495.582 50.5859 499.625 64.4141 499.625 80H438.102C438.102 69.5703 434.879 61.4844 428.434 55.7422C422.105 50 413.492 47.1289 402.594 47.1289C391.578 47.1289 382.848 49.5898 376.402 54.5117C370.074 59.3164 366.91 65.4687 366.91 72.9688C366.91 79.5312 370.426 85.5078 377.457 90.8984C384.488 96.1719 396.852 101.68 414.547 107.422C432.242 113.047 446.773 119.141 458.141 125.703C485.797 141.641 499.625 163.613 499.625 191.621C499.625 214.004 491.188 231.582 474.312 244.355C457.438 257.129 434.293 263.516 404.879 263.516C384.137 263.516 365.328 259.824 348.453 252.441C331.695 244.941 319.039 234.746 310.484 221.855C302.047 208.848 297.828 193.906 297.828 177.031H359.703C359.703 190.742 363.219 200.879 370.25 207.441C377.398 213.887 388.941 217.109 404.879 217.109C415.074 217.109 423.102 214.941 428.961 210.605C434.938 206.152 437.926 199.941 437.926 191.973Z"
            fill="#fff"
          />
        </svg>
      </Logo>

      <NavItems>
        {!userIsExternal && (
          <NavItem
            active={location.pathname.indexOf('monitor') >= 0 ? 1 : 0}
            to="/monitor/dashboard"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 21v70h106V21H11zm-2-6a4 4 0 00-4 4v74a4 4 0 004 4h110a4 4 0 004-4V19a4 4 0 00-4-4H9z"
              />
              <path d="M39 111a3 3 0 013-3h44a3 3 0 110 6H42a3 3 0 01-3-3z" />
            </svg>
            <span>Monitor</span>
          </NavItem>
        )}

        {!userIsExternal && location.pathname.indexOf('monitor') >= 0 && (
          <SubNav>
            <Triangle />

            <NavItem
              active={location.pathname.indexOf('dashboard') >= 0 ? 1 : 0}
              to="/monitor/dashboard"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 10a2 2 0 00-2 2v45a2 2 0 002 2h104a2 2 0 002-2V12a2 2 0 00-2-2H12zm4 6v37h96V16H16z"
                />
                <path d="M10 84a2 2 0 012-2h104a2 2 0 012 2v2a2 2 0 01-2 2H12a2 2 0 01-2-2v-2zM12 112a2 2 0 00-2 2v2a2 2 0 002 2h104a2 2 0 002-2v-2a2 2 0 00-2-2H12z" />
              </svg>
              <span>Dashboard</span>
            </NavItem>

            <NavItem
              active={location.pathname.indexOf('moodboard') >= 0 ? 1 : 0}
              to="/monitor/moodboard"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 15v38h38V15H15zm-2-6a4 4 0 00-4 4v42a4 4 0 004 4h42a4 4 0 004-4V13a4 4 0 00-4-4H13zM74 15v38h38V15H74zm-2-6a4 4 0 00-4 4v42a4 4 0 004 4h42a4 4 0 004-4V13a4 4 0 00-4-4H72zM15 74v38h38V74H15zm-2-6a4 4 0 00-4 4v42a4 4 0 004 4h42a4 4 0 004-4V72a4 4 0 00-4-4H13zM74 74v38h38V74H74zm-2-6a4 4 0 00-4 4v42a4 4 0 004 4h42a4 4 0 004-4V72a4 4 0 00-4-4H72z"
                />
              </svg>
              <span>Moodboard</span>
            </NavItem>

            <NavItem active={location.pathname.indexOf('radar') >= 0 ? 1 : 0} to="/monitor/radar">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M34 72c0-2.753.37-5.419 1.065-7.951l22.042 12.725a7.04 7.04 0 00-.091 1.7L18.49 117c.979.99 1.99 1.948 3.03 2.872.775.688 1.95.634 2.682-.099l35.934-35.933A6.968 6.968 0 0064 85a6.967 6.967 0 003.863-1.162l35.934 35.934c.733.733 1.907.787 2.682.099a63.692 63.692 0 003.03-2.872C120.941 105.438 128 89.543 128 72c0-35.346-28.654-64-64-64C28.654 8 0 36.654 0 72c0 17.543 7.059 33.438 18.491 44.999l4.243-4.242C12.387 102.281 6 87.887 6 72a57.826 57.826 0 014.398-22.193l19.325 11.157A35.975 35.975 0 0028 72c0 9.811 3.925 18.706 10.29 25.2l4.243-4.243C37.253 87.549 34 80.155 34 72zm71.266 40.757C115.613 102.281 122 87.887 122 72a57.822 57.822 0 00-4.471-22.37L98.217 60.78A35.969 35.969 0 01100 72c0 9.811-3.925 18.706-10.29 25.2l15.556 15.557zm-8.524-55.744l19.109-11.032C106.455 27.293 87.253 14.385 65 14.008v22.006c14.107.384 26.188 8.884 31.742 21zm-5.225 3.017L68.955 73.056A6.98 6.98 0 0065 71.071V42.016c11.884.39 22.02 7.691 26.517 18.014zM70.855 76.578L92.883 63.86A30.023 30.023 0 0194 72c0 8.155-3.254 15.55-8.533 20.957L70.984 78.474a7.1 7.1 0 00-.129-1.896zM61 42.148c-11.08 1.1-20.385 8.228-24.592 18.057l22.5 12.991A7.02 7.02 0 0161 71.674V42.148zm0-28.072C39.534 15.17 21.148 27.935 12.065 46.151l19.114 11.035C36.436 45.558 47.692 37.221 61 36.123V14.076z"
                />
              </svg>
              <span>Radar</span>
            </NavItem>
            <NavItem
              active={location.pathname.indexOf('roadmap') >= 0 ? 1 : 0}
              to="/monitor/roadmap"
            >
              <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.645 97c-.57 0-1.032.443-1.032.99v12.871H1.033c-.57 0-1.033.444-1.033.99v4.159c0 .547.462.99 1.032.99h125.936c.57 0 1.032-.443 1.032-.99v-4.159c0-.546-.462-.99-1.032-.99h-18.581v-12.87c0-.548-.462-.991-1.032-.991h-4.129c-.57 0-1.032.443-1.032.99v12.871H67.097v-12.87c0-.548-.462-.991-1.032-.991h-4.13c-.57 0-1.032.443-1.032.99v12.871H25.807v-12.87c0-.548-.463-.991-1.033-.991h-4.129z" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M64.5 78.763c18.37 0 33.263-14.892 33.263-33.263 0-18.37-14.892-33.263-33.263-33.263-18.37 0-33.263 14.892-33.263 33.263 0 18.37 14.892 33.263 33.263 33.263zm0 6.237C86.315 85 104 67.315 104 45.5S86.315 6 64.5 6 25 23.685 25 45.5 42.685 85 64.5 85z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M61 21.5a1 1 0 011-1h4a1 1 0 011 1v22.843a1 1 0 00.293.707l15.121 15.122a1 1 0 010 1.414l-2.828 2.828a1 1 0 01-1.414 0l-16.88-16.879a1 1 0 01-.292-.707V21.5z"
                />
              </svg>
              <span>Roadmap</span>
            </NavItem>
            <NavItem
              active={location.pathname.indexOf('scenario') >= 0 ? 1 : 0}
              to="/monitor/scenario"
            >
              <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.38 40.557L9.99 57.25l-5.98-.498 2.21-26.52 25.52-2.22.52 5.978-17.288 1.503L44 70.416V117.5h-6V72.584L11.38 40.557zM64.943 3.992l20.015 17.235-3.915 4.546-12.938-11.14L69 71.476V122.5h-6V71.524l-.895-56.825-12.084 11.018-4.042-4.434 18.964-17.29zM118.619 40.557l1.391 16.692 5.98-.498-2.21-26.52-25.52-2.22-.52 5.978 17.288 1.503L86 70.416V117.5h6V72.584l26.619-32.027z"
                />
              </svg>
              <span>Scenario</span>
            </NavItem>
          </SubNav>
        )}

        <NavItem active={location.pathname.indexOf('evaluate') >= 0 ? 1 : 0} to="/evaluate/rating">
          <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M40 11v14h48V11H40zm-2-6a4 4 0 00-4 4v18a4 4 0 004 4h52a4 4 0 004-4V9a4 4 0 00-4-4H38zM54.116 49.002a1 1 0 01.077 1.412L42.157 63.825a1 1 0 01-1.438.053l-7.731-7.435a1 1 0 01-.028-1.414l2.08-2.163a1 1 0 011.414-.027l4.001 3.848a1 1 0 001.438-.053l8.578-9.56a1 1 0 011.413-.076l2.232 2.004z"
            />
            <path d="M34.707 90.293a1 1 0 000 1.414l2.121 2.121a1 1 0 001.415 0l5.025-5.025 5.025 5.025a1 1 0 001.414 0l2.121-2.12a1 1 0 000-1.415l-5.025-5.025 5.025-5.025a1 1 0 000-1.415l-2.12-2.12a1 1 0 00-1.415 0l-5.025 5.024-5.025-5.025a1 1 0 00-1.415 0l-2.12 2.121a1 1 0 000 1.415l5.024 5.025-5.025 5.025zM66 56a2 2 0 012-2h26a2 2 0 012 2v2a2 2 0 01-2 2H68a2 2 0 01-2-2v-2zM66 84a2 2 0 012-2h26a2 2 0 012 2v2a2 2 0 01-2 2H68a2 2 0 01-2-2v-2z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 26c0-6.075 4.925-11 11-11h9.5v6H25a5 5 0 00-5 5v84a5 5 0 005 5h78a5 5 0 005-5V26a5 5 0 00-5-5h-9v-6h9c6.075 0 11 4.925 11 11v84c0 6.075-4.925 11-11 11H25c-6.075 0-11-4.925-11-11V26z"
            />
          </svg>
          <span>Evaluate</span>
        </NavItem>

        {location.pathname.indexOf('evaluate') >= 0 && (
          <SubNav>
            <Triangle />

            <NavItem
              active={location.pathname.indexOf('rating') >= 0 ? 1 : 0}
              to="/evaluate/rating"
            >
              <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M64 16.652L50.612 43.78a9.804 9.804 0 01-7.382 5.363l-29.936 4.35 21.662 21.115a9.804 9.804 0 012.82 8.678L32.662 113.1l26.776-14.077a9.804 9.804 0 019.124 0L95.338 113.1l-5.114-29.815a9.804 9.804 0 012.82-8.678l21.662-21.115-29.936-4.35a9.804 9.804 0 01-7.382-5.363L64 16.652zm2.93-8.83c-1.198-2.43-4.662-2.43-5.86 0L44.75 40.886a3.268 3.268 0 01-2.46 1.788L5.8 47.976c-2.68.39-3.75 3.684-1.81 5.574l26.403 25.737c.77.751 1.122 1.833.94 2.893L25.1 118.522c-.458 2.669 2.344 4.705 4.742 3.445l32.636-17.158a3.265 3.265 0 013.042 0l32.636 17.158c2.398 1.26 5.2-.776 4.742-3.445L96.666 82.18a3.268 3.268 0 01.94-2.893L124.01 53.55c1.939-1.89.869-5.184-1.812-5.574L85.71 42.674a3.268 3.268 0 01-2.461-1.788L66.93 7.822z"
                />
              </svg>
              <span>Rating</span>
            </NavItem>
          </SubNav>
        )}

        {!userIsExternal && (
          <NavItem active={location.pathname.indexOf('scout') >= 0 ? 1 : 0} to="/scout/signals">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M86.521 92.664a47.45 47.45 0 01-29.89 10.537C30.325 103.201 9 81.89 9 55.601 9 29.31 30.325 8 56.63 8c26.306 0 47.631 21.311 47.631 47.6 0 12.369-4.72 23.635-12.458 32.099l26.59 26.573a2.068 2.068 0 010 2.927l-2.196 2.195a2.073 2.073 0 01-2.929 0l-26.747-26.73zM97.013 55.6c0 22.288-18.08 40.357-40.382 40.357-22.303 0-40.383-18.069-40.383-40.357 0-22.289 18.08-40.357 40.383-40.357 22.302 0 40.382 18.068 40.382 40.357z"
              />
            </svg>
            <span>Scout</span>
          </NavItem>
        )}

        {!!(!userIsExternal && location.pathname.indexOf('scout') >= 0) && (
          <SubNav>
            <Triangle />

            <NavItem active={location.pathname.indexOf('signals') >= 0 ? 1 : 0} to="/scout/signals">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path d="M49 32a2 2 0 00-2 2v2a2 2 0 002 2h48a2 2 0 002-2v-2a2 2 0 00-2-2H49zM47 58a2 2 0 012-2h48a2 2 0 012 2v2a2 2 0 01-2 2H49a2 2 0 01-2-2v-2zM49 80a2 2 0 00-2 2v2a2 2 0 002 2h48a2 2 0 002-2v-2a2 2 0 00-2-2H49z" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25 8a4 4 0 014-4h85a4 4 0 014 4v112a4 4 0 01-4 4H25v-.166c-.65.109-1.319.166-2 .166-6.627 0-12-5.373-12-12V35a4 4 0 014-4h10V8zm6 110V10h81v108H31zm-6-81v80.659A6 6 0 0117 112V37h8z"
                />
              </svg>
              <span>Signals</span>
            </NavItem>
          </SubNav>
        )}

        {!userIsExternal && (
          <NavItem active={location.pathname.indexOf('manage') >= 0 ? 1 : 0} to="/manage/request">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <path d="M20 6a2 2 0 00-2 2v18H7a2 2 0 00-2 2v2a2 2 0 002 2h11v88a2 2 0 002 2h2a2 2 0 002-2V32h11a2 2 0 002-2v-2a2 2 0 00-2-2H24V8a2 2 0 00-2-2h-2zM63 6a2 2 0 00-2 2v53H50a2 2 0 00-2 2v2a2 2 0 002 2h11v53a2 2 0 002 2h2a2 2 0 002-2V67h11a2 2 0 002-2v-2a2 2 0 00-2-2H67V8a2 2 0 00-2-2h-2zM104 8a2 2 0 012-2h2a2 2 0 012 2v88h11a2 2 0 012 2v2a2 2 0 01-2 2h-11v18a2 2 0 01-2 2h-2a2 2 0 01-2-2v-18H93a2 2 0 01-2-2v-2a2 2 0 012-2h11V8z" />
            </svg>
            <span>Manage</span>
          </NavItem>
        )}

        {!!(!userIsExternal && location.pathname.indexOf('manage') >= 0) && (
          <SubNav>
            <Triangle />

            <NavItem
              active={location.pathname.indexOf('request') >= 0 ? 1 : 0}
              to="/manage/request"
            >
              <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M115.205 88.07C118.916 80.504 121 71.995 121 63c0-31.48-25.52-57-57-57S7 31.52 7 63s25.52 57 57 57c8.721 0 16.985-1.959 24.376-5.46l30.98 6.586a2 2 0 002.372-2.372l-6.523-30.684zM64 114c4.648 0 9.152-.622 13.43-1.787a50.723 50.723 0 0010.146-3.977l27.003 5.74-5.688-26.752a50.689 50.689 0 004.047-9.82A51.025 51.025 0 00115 63c0-28.166-22.834-51-51-51S13 34.834 13 63s22.834 51 51 51z"
                />
                <path d="M58.24 72.32c0-2.133.347-4 1.04-5.6.747-1.653 1.627-3.04 2.64-4.16 1.067-1.12 2.453-2.453 4.16-4 2.133-1.867 3.707-3.467 4.72-4.8 1.013-1.387 1.52-3.04 1.52-4.96 0-2.613-1.013-4.693-3.04-6.24-2.027-1.6-4.827-2.4-8.4-2.4-6.24 0-11.013 2.107-14.32 6.32l-5.84-4.16c2.24-2.88 5.093-5.093 8.56-6.64 3.52-1.547 7.573-2.32 12.16-2.32 5.813 0 10.427 1.28 13.84 3.84 3.413 2.507 5.12 5.973 5.12 10.4 0 2.187-.373 4.133-1.12 5.84-.693 1.653-1.547 3.04-2.56 4.16-1.013 1.12-2.373 2.453-4.08 4-2.187 1.973-3.813 3.707-4.88 5.2-1.013 1.493-1.52 3.333-1.52 5.52h-8zm4.08 18.16c-1.493 0-2.747-.48-3.76-1.44-.96-1.013-1.44-2.24-1.44-3.68s.48-2.64 1.44-3.6c1.013-1.013 2.267-1.52 3.76-1.52 1.493 0 2.72.507 3.68 1.52.96.96 1.44 2.16 1.44 3.6s-.507 2.667-1.52 3.68c-.96.96-2.16 1.44-3.6 1.44z" />
              </svg>
              <span>Request</span>
            </NavItem>
            {userIsAdmin && (
              <NavItem
                active={location.pathname.indexOf('new-topic') >= 0 ? 1 : 0}
                to="/manage/new-topic"
              >
                <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M64 118c29.823 0 54-24.177 54-54S93.823 10 64 10 10 34.177 10 64s24.177 54 54 54zm0 6c33.137 0 60-26.863 60-60S97.137 4 64 4 4 30.863 4 64s26.863 60 60 60z"
                  />
                  <path d="M61 42h6v44h-6z" />
                  <path d="M86 61v6H42v-6z" />
                </svg>
                <span>New Topic</span>
              </NavItem>
            )}
          </SubNav>
        )}
      </NavItems>

      <NavButton onClick={handleSignOut}>
        <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
          <path d="M58.213 12c.992 0 1.796.803 1.796 1.793v1.793c0 .99-.804 1.793-1.796 1.793H21.389v93.242h36.824c.992 0 1.796.802 1.796 1.793v1.793c0 .99-.804 1.793-1.796 1.793h-38.62A3.59 3.59 0 0116 112.414V15.586A3.59 3.59 0 0119.593 12h38.62z" />
          <path d="M82.495 32.883c-.702-.7-1.839-.7-2.54 0l-1.27 1.268a1.79 1.79 0 000 2.536L103.8 61.759H46.088c-.992 0-1.796.802-1.796 1.793v1.793c0 .99.804 1.793 1.796 1.793h57.713L78.684 92.21a1.79 1.79 0 000 2.535l1.27 1.268c.702.7 1.84.7 2.54 0l29.717-29.663a2.687 2.687 0 000-3.804L82.495 32.883z" />
        </svg>
        <span>Sign Out</span>
      </NavButton>
    </Wrapper>
  )
}

export default withRouter(Nav)

// ================================================================================================

const Wrapper = styled(motion.div)`
  width: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  background: #102a43;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Logo = styled(motion.div)`
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-shrink: 0;

  svg {
    width: 2rem;
  }
`

const SubNav = styled.div`
  position: relative;
  background: #0a1927;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.75rem 0 0.625rem 0;
  /* margin: 0 0 1.5rem; */

  > svg {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    path {
      fill: #122a43;
    }
  }
`

const NavItems = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
`

interface NavItemProps {
  active?: boolean
  topLevel?: boolean
}
const NavItem = styled(Link)<NavItemProps>`
  position: relative;
  padding: 0.75rem 0;
  padding: 1.75vh 0;
  /* margin-bottom: 1rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${({ active }) => (active ? '1' : '0.33')};
  background: ${({ active, topLevel }) => (active && topLevel ? '#102233' : 'rgba(0,0,0,0)')};

  svg {
    fill: #fff;
    height: 22px;
    width: 22px;
    transition: 0.25s;
  }

  span {
    font-family: 'Montserrat', 'Open Sans', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 600;
    margin-top: 6px;
    transition: 0.25s;
  }

  &:hover {
    opacity: 1;
  }
`

const NavButton = styled.button`
  position: relative;
  padding: 0.75rem 0;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.33;

  svg {
    fill: #fff;
    height: 22px;
    width: 22px;
    transition: 0.25s;
  }

  span {
    font-family: 'Montserrat', 'Open Sans', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 600;
    margin-top: 6px;
    transition: 0.25s;
  }

  &:hover {
    opacity: 1;
  }
`

// ================================================================================================

const Triangle = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 10L0 0h16L8 10z" />
  </svg>
)
