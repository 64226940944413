import { useCallback } from 'react'
import useAsyncLoader from './useAsyncLoader'
import * as Parse from 'parse'
import { File } from 'parse'
import { Document } from '../types'
import { logError } from '../utils/error'

interface Props {
  topicId: string
}

interface Response {
  error?: Error
  documents?: Array<Document>
  loading?: boolean
}

const useDocumentLoader = ({ topicId }: Props): Response => {
  const { data: documents, error, loading } = useAsyncLoader(
    // @ts-ignore
    useCallback(() => {
      const Document = Parse.Object.extend('Document')
      const documents = new Parse.Query(Document)

      const Topic = Parse.Object.extend('Topic')
      const topic = new Topic()
      topic.id = topicId && typeof topicId === 'string' ? topicId : 'not-existing-id'
      documents.equalTo('topic', topic)

      return documents.find().then(data => data)
    }, [topicId])
  )

  return {
    error,
    documents: Array.isArray(documents)
      ? documents.map(d => {
          try {
            const file: File = d.get('file')
            const label: string = d.get('label')

            if (file) {
              return {
                name: label && typeof label === 'string' ? label : file.name(),
                url: file.url()
              }
            }
          } catch (error) {
            logError(error)
          }
        })
      : [],
    loading
  }
}

export default useDocumentLoader
