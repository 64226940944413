import * as Parse from 'parse'

interface Props {
  rating: any
  user: string
  topic: string
}
type Response = Promise<{ error?: Error | null }>

const setRating = async ({ rating, user, topic }: Props): Response => {
  try {
    const RatingObject = Parse.Object.extend('Rating')
    const ratingRequest = new RatingObject()

    ratingRequest.set('topic', topic)
    ratingRequest.set('user', user)

    const keys = Object.keys(rating)

    if (keys && Array.isArray(keys) && keys.length) {
      keys.forEach(key => {
        ratingRequest.set(key, rating[key])
      })
    }

    await ratingRequest.save()

    return { error: null }
  } catch (error) {
    return { error }
  }
}

export default setRating
