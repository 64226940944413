import React, { useCallback, useState, SyntheticEvent } from 'react'
import styled from 'styled-components'
import * as Parse from 'parse'
import { RouteComponentProps } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'

// Components
import RatingInput from '../components/RatingInput'
import Layout from '../components/Layout'
import TextArea from '../components/TextArea'
import TextInput from '../components/TextInput'

// Store
import { useDialogStore, useUserStore } from '../store'

// Types
import { CriterionData, Criterion } from '../types'

// Utils
import { data } from '../utils/radar'
import { getUrlParams } from '../utils/url'

// ================================================================================================

const NewTopicPage: React.FC<RouteComponentProps> = ({ location }) => {
  // Url params
  const { q: urlSearchQuery } = getUrlParams(location.search)

  // Global store
  const { showErrorDialog, showSuccessDialog } = useDialogStore()
  const { email, userIsAdmin } = useUserStore()

  // Local state
  const [activeOptions, setActiveOptions] = useState(null)
  const [loading, setLoading] = useState(false)
  const [newTrendDecription, setNewTrendDecription] = useState('')
  const [newTrendQuery, setNewTrendQuery] = useState(
    urlSearchQuery && typeof urlSearchQuery === 'string' ? urlSearchQuery : ''
  )
  const [newTrendTitle, setNewTrendTitle] = useState('')
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null)
  const [uploading, setUploading] = useState(false)

  // Handler
  const handleRatingInputToggle = (key: string, option: CriterionData) => {
    const { value } = option

    if (activeOptions) {
      if (activeOptions[key]) {
        if (activeOptions[key] === value) {
          setActiveOptions({ ...activeOptions, [key]: null })
        } else {
          setActiveOptions({ ...activeOptions, [key]: value })
        }
      } else {
        setActiveOptions({ ...activeOptions, [key]: value })
      }
    } else {
      setActiveOptions({ [key]: value })
    }
  }

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (newTrendTitle.length) {
      setLoading(true)

      try {
        let Request

        if (userIsAdmin) {
          Request = Parse.Object.extend('Topic')
        } else {
          Request = Parse.Object.extend('TopicRequest')
        }

        const request = new Request()

        request.set('title', newTrendTitle)
        if (newTrendQuery) request.set('query', newTrendQuery)
        if (newTrendDecription) request.set('description', newTrendDecription)
        if (uploadedFileUrl) request.set('imageUrl', uploadedFileUrl)
        if (!userIsAdmin && email) request.set('user', email)

        // Assessment
        Object.keys(activeOptions).forEach(key => {
          const val = activeOptions[key]
          request.set(key, val)
        })

        await request.save()

        showSuccessDialog('Submission successful!')

        setActiveOptions(null)
        setNewTrendDecription('')
        setNewTrendQuery('')
        setNewTrendTitle('')
        setUploadedFileUrl(null)
        setUploadedFileName(null)
      } catch (error) {
        showErrorDialog(`Error: ${error.code} ${error.message}`)
      }

      setLoading(false)
    }
  }

  // File upload
  const onDrop = useCallback(acceptedFiles => {
    setUploading(true)

    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      const { name: uploadName } = file
      if (uploadName) setUploadedFileName(uploadName)

      reader.onabort = () => setUploading(false)
      reader.onerror = () =>
        showErrorDialog(
          'Upload failed. Please make sure your file is a JPEG or PNG. The maximum file size is 5MB.'
        )
      reader.onload = () => {
        const file = typeof reader.result === 'string' ? reader.result : ''
        const name = 'upload.jpg'
        const parseFile = new Parse.File(name, { uri: file })

        parseFile
          .save()
          .then(response => {
            const url = response.url()

            if (url) {
              setUploadedFileUrl(url)
              setUploading(false)
            }
          })
          .catch(error => {
            setUploadedFileName(null)
            setUploading(false)
            showErrorDialog(error.message ? error.message : error)
          })
      }

      reader.readAsDataURL(file)
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
    multiple: false
  })

  return (
    <Layout>
      <Wrapper>
        <PageTitle>New Topic</PageTitle>

        <Widget>
          <WidgetTitle>New Trend</WidgetTitle>
          <WidgetSubTitle>
            Submit a new trend topic. Feel free to fill as many topic information as possible.{' '}
            <br />
            We'll try to complete missing information and setup all necessary data streams in the
            background.
          </WidgetSubTitle>

          <Form>
            <div>
              <TextInput
                label="Trend Name"
                onTextChange={input => setNewTrendTitle(input)}
                onClear={() => setNewTrendTitle('')}
                placeholder="Short but descriptive name"
                value={newTrendTitle}
                width="33%"
              />
              <TextInput
                label="Search Query"
                onTextChange={input => setNewTrendQuery(input)}
                onClear={() => setNewTrendQuery('')}
                placeholder='e.g. (Term OR "Other term") AND "A third term"'
                value={newTrendQuery}
                width="64%"
              />
            </div>
            <TextArea
              label="Trend Description"
              onTextChange={input => setNewTrendDecription(input)}
              placeholder="Trend description"
              value={newTrendDecription}
            />

            <Label>Image</Label>
            <FileDrop active={!!uploadedFileName} {...getRootProps()}>
              <input {...getInputProps()} />
              <span>
                {uploadedFileName
                  ? uploadedFileName
                  : 'Drag and drop a file here, or click to select file'}
              </span>
              <button>Upload file</button>
            </FileDrop>
            <FileDropInfo>
              {uploading
                ? 'Uploading...'
                : uploadedFileUrl
                ? `Image successfully uploaded`
                : 'No file uploaded'}
            </FileDropInfo>

            {data.map((c: Criterion) => {
              return (
                <>
                  <Label>{c.label}</Label>
                  <RatingInput
                    activeOption={activeOptions ? activeOptions[c.value] : null}
                    options={c.data}
                    onToggle={option => handleRatingInputToggle(c.value, option)}
                  />
                </>
              )
            })}

            <Submit loading={loading} onClick={handleSubmit}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
                  <path d="M17 21v-8H7v8M7 3v5h8" />
                </svg>
              </div>
              <span>Submit</span>
            </Submit>
          </Form>
        </Widget>
      </Wrapper>
    </Layout>
  )
}

export default NewTopicPage

// ================================================================================================

const Wrapper = styled.section`
  background: ${({ theme }) => theme.base};
  padding: 3rem 4rem;
`

const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
  margin: 0 0 4rem;
`

const Widget = styled.div`
  background: ${({ theme }) => theme.surface};
  padding: 2rem;
  width: 100%;
  max-width: 960px;
`

const WidgetTitle = styled.h2`
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  color: ${({ theme }) => theme.text};
`

const WidgetSubTitle = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.text};
  margin: 0.5rem 0 3rem;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    &:first-child {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      > div {
        margin-bottom: 1.25rem;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
`

const Label = styled.label`
  font-size: 0.75rem;
  margin: 1.25rem 0 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
`

interface FileDropProps {
  active?: boolean
}
const FileDrop = styled.div<FileDropProps>`
  height: 3.125rem;
  background: #edf1f4;
  padding: 0 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  span {
    font-size: 0.9375rem;
    color: ${({ active, theme }) => (active ? theme.text : '#ced2d7')};
  }

  button {
    height: 1.875rem;
    border-radius: 4px;
    padding: 0 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.825);
    color: ${({ theme }) => theme.text};
    font-size: 0.625rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  &:focus {
    outline: none;
  }
`

const FileDropInfo = styled.p`
  color: #b2b6b9;
  font-size: 0.825rem;
  font-weight: 400;
  margin-top: 0.5rem;
`

interface SubmitProps {
  loading?: boolean
}
const Submit = styled.button<SubmitProps>`
  height: 2.75rem;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2cb1bc;
  border-radius: 1.5rem;
  margin: 3rem 0 0;
  pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};

  svg {
    width: 1rem;
    height: 1rem;
    margin: 3px 0 0;
  }

  span {
    font-size: 0.8125rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    margin: 0 0 0 0.5rem;
    line-height: 1;
  }

  &:hover {
    background: #299ba5;
  }
`
