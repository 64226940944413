import { useEffect, useState } from 'react'

interface Response {
  count?: number
  data?: any
  error?: Error
  loading?: boolean
}

const useAsyncLoader = (requestFn: () => Promise<Response>) => {
  const [error, setError] = useState(null)
  const [data, setData] = useState<any | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // canceled prevents setting data on unmounted component
    let canceled = false
    setLoading(true)
    requestFn()
      .then(data => {
        setLoading(false)
        if (!canceled) setData(data)
      })
      .catch(error => {
        setLoading(false)
        setError(error)
      })

    return () => {
      canceled = true
    }
  }, [requestFn])

  return {
    count: data && data.count ? data.count : 0,
    data: data && data.results ? data.results : data,
    error,
    loading
  }
}

export default useAsyncLoader
