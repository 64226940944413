import { Criteria } from '../../types'

const criteria: Criteria = [
  {
    value: 'customerBenefit',
    label: 'Customer Benefit',
    data: [
      { value: 'low', label: 'Low' },
      { value: 'mid', label: 'Mid' },
      { value: 'high', label: 'High' }
    ]
  },
  {
    value: 'internalCompetency',
    label: 'Internal Competency',
    data: [
      { value: 'very high', label: 'Very High' },
      { value: 'high', label: 'High' },
      { value: 'mid', label: 'Mid' },
      { value: 'low', label: 'Low' }
    ]
  },
  {
    value: 'maturity',
    label: 'Maturity',
    data: [
      { value: 'innovators', label: 'Innovators' },
      { value: 'early adopters', label: 'Early Adopters' },
      { value: 'early majority', label: 'Early Majority' },
      { value: 'late majority', label: 'Late Majority' },
      { value: 'laggards', label: 'Laggards' }
    ]
  },
  {
    value: 'marketPotential',
    label: 'Market Potential',
    data: [
      { value: 'low', label: 'Low' },
      { value: 'mid', label: 'Mid' },
      { value: 'high', label: 'High' },
      { value: 'very high', label: 'Very High' }
    ]
  },
  {
    value: 'needForAction',
    label: 'Need for Action',
    data: [
      { value: 'act', label: 'Act' },
      { value: 'analyze', label: 'Analyze' },
      { value: 'aware', label: 'Aware' }
    ]
  },
  {
    value: 'novelty',
    label: 'Novelty',
    data: [
      { value: 'breakthrough', label: 'Breakthrough' },
      { value: 'cross industry', label: 'Cross Industry' },
      { value: 'industry known', label: 'Industry Known' }
    ]
  },
  {
    value: 'trendDriver',
    label: 'Trend Driver',
    data: [
      { value: 'economical', label: 'Economical' },
      { value: 'environmental', label: 'Environmental' },
      { value: 'legal', label: 'Legal' },
      { value: 'political', label: 'Political' },
      { value: 'social', label: 'Social' },
      { value: 'technological', label: 'Technological' }
    ]
  },
  {
    value: 'strategicFit',
    label: 'Strategic Fit',
    data: [
      { value: 'low', label: 'Low' },
      { value: 'mid', label: 'Mid' },
      { value: 'high', label: 'High' }
    ]
  },
  {
    value: 'uncertainty',
    label: 'Uncertainty',
    data: [
      { value: 'low', label: 'Low' },
      { value: 'mid', label: 'Mid' },
      { value: 'high', label: 'High' }
    ]
  }
  // {
  //   value: 'years',
  //   data: [
  //     { boundries: [0, 1], value: 'short', label: '0 - 1 Y' },
  //     { boundries: [2, 5], value: 'mid', label: '2 - 5 Y' },
  //     { boundries: [6, 10], value: 'long', label: '6 - 10 Y' }
  //   ]
  // },
]

export default criteria
