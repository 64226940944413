import { useCallback } from 'react'
import useAsyncLoader from './useAsyncLoader'
import * as Parse from 'parse'
import { Topic } from '../types'

interface Response {
  error?: Error
  loading?: boolean
  topics?: Array<Topic>
}

const useTopicLoader = (): Response => {
  const { data: topics, error, loading } = useAsyncLoader(
    // @ts-ignore
    useCallback(() => {
      const Topic = Parse.Object.extend('Topic')
      const query = new Parse.Query(Topic)
      query.ascending('title')

      return query.find().then(data => data)
    }, [])
  )

  return {
    error,
    loading,
    topics: Array.isArray(topics)
      ? topics.map(t => ({
          customerBenefit: t.get('customerBenefit'),
          description: t.get('description'),
          id: t.id,
          identifier: t.get('identifier'),
          image: t.get('image') && t.get('image').url() ? t.get('image').url() : '', // primary img
          imageUrl: t.get('imageUrl') ? t.get('imageUrl') : '', // user upload
          internalCompetency: t.get('internalCompetency'),
          marketPotential: t.get('marketPotential'),
          maturity: t.get('maturity'),
          needForAction: t.get('needForAction'),
          novelty: t.get('novelty'),
          query: t.get('query'),
          strategicFit: t.get('strategicFit'),
          title: t.get('title'),
          trendDriver: t.get('trendDriver'),
          trending: t.get('highlightingRadar'),
          uncertainty: t.get('uncertainty'),
          visibility: t.get('visibility')
        }))
      : []
  }
}

export default useTopicLoader
