import React, { useEffect, useState, SyntheticEvent } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'

// API
import { getRating, setRating } from '../api'

// Components
import Excerpt from '../components/Excerpt'
import RatingInput from '../components/RatingInput'
import Layout from '../components/Layout'
import NoResults from '../components/NoResults'

// Store
import { useDialogStore, useTopicStore, useUserStore } from '../store'

// Types
import { CriterionData, Criterion, Topic } from '../types'

// Utils
import { data } from '../utils/radar'

// ================================================================================================

const RatingPage: React.FC<RouteComponentProps> = ({ location }) => {
  // Global store
  const { showErrorDialog, showSuccessDialog } = useDialogStore()
  const { topics } = useTopicStore()
  const { email } = useUserStore()

  // Local state
  const [activeOptions, setActiveOptions] = useState(null)
  const [loading, setLoading] = useState(false)
  const [filteredTopics, setFilteredTopics] = useState<Array<Topic>>([])
  const [filteredTopicsIndex, setFilteredTopicsIndex] = useState(0)
  const [userRating, setUserRating] = useState(null)

  //=============================================
  // Effect

  useEffect(() => {
    fetchRatings()
  }, [])

  useEffect(() => {
    if (topics && Array.isArray(topics) && topics.length) {
      if (userRating && Array.isArray(userRating) && userRating.length) {
        if (filteredTopics && filteredTopics.length) {
          const filtered = filteredTopics.filter((t: Topic) => !userRating.find(ur => ur === t.id))
          setFilteredTopics(filtered)
        } else {
          const filtered = topics.filter((t: Topic) => !userRating.find(ur => ur === t.id))
          setFilteredTopics(filtered)
        }
      } else {
        setFilteredTopics(topics)
      }
    }
  }, [topics, userRating])

  //=============================================
  // Helper

  const fetchRatings = async () => {
    const { rating } = await getRating(email)
    if (rating) setUserRating(rating)
  }

  //=============================================
  // Handler

  const handleNext = () => {
    if (filteredTopicsIndex === filteredTopics.length - 1) {
      setFilteredTopicsIndex(0)
    } else {
      setFilteredTopicsIndex(prev => prev + 1)
    }
  }

  const handlePrev = () => {
    if (filteredTopicsIndex === 0) {
      setFilteredTopicsIndex(filteredTopics.length - 1)
    } else {
      setFilteredTopicsIndex(prev => prev - 1)
    }
  }

  const handleRatingInputToggle = (key: string, option: CriterionData) => {
    const { value } = option

    if (activeOptions) {
      if (activeOptions[key]) {
        if (activeOptions[key] === value) {
          setActiveOptions({ ...activeOptions, [key]: null })
        } else {
          setActiveOptions({ ...activeOptions, [key]: value })
        }
      } else {
        setActiveOptions({ ...activeOptions, [key]: value })
      }
    } else {
      setActiveOptions({ [key]: value })
    }
  }

  const handleSubmit = async (e: SyntheticEvent) => {
    setLoading(true)

    if (activeOptions) {
      const { error } = await setRating({
        rating: activeOptions,
        topic: filteredTopics[filteredTopicsIndex].id,
        user: email
      })

      if (error) {
        showErrorDialog(error)
      } else {
        const submittedTopicId = filteredTopics[filteredTopicsIndex]
          ? filteredTopics[filteredTopicsIndex].id
          : ''
        setUserRating(prev => [...prev, submittedTopicId])
        setActiveOptions(null)
        showSuccessDialog('Rating successful!')
      }
    }

    setLoading(false)
  }

  return (
    <Layout>
      <Wrapper>
        <PageTitle>Topic Rating</PageTitle>

        <Widget>
          {!loading && !filteredTopics.length ? (
            <NoResults headline="Great work!" message="No trend topics to rate" />
          ) : (
            !!(filteredTopics && filteredTopics.length) && (
              <>
                <WidgetNav>
                  <button onClick={handlePrev}>
                    <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M53.726 63.5L86 7.496 75.603 1.504 39.876 63.5l35.727 61.996L86 119.504 53.726 63.5z"
                      />
                    </svg>
                    <span>Previous Topic</span>
                  </button>
                  <button onClick={handleNext}>
                    <span>Next Topic</span>
                    <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M72.274 63.5L40 7.496l10.397-5.992L86.124 63.5l-35.727 61.996L40 119.504 72.274 63.5z"
                      />
                    </svg>
                  </button>
                </WidgetNav>
                <AnimatePresence>
                  <Header>
                    <Thumb
                      src={
                        filteredTopics[filteredTopicsIndex].image
                          ? filteredTopics[filteredTopicsIndex].image
                          : filteredTopics[filteredTopicsIndex].imageUrl
                          ? filteredTopics[filteredTopicsIndex].imageUrl
                          : ''
                      }
                    />
                    <TextWrapper>
                      <h1>{filteredTopics[filteredTopicsIndex].title}</h1>
                      {/* <p>{getExcerpt(filteredTopics[filteredTopicsIndex].description, 140)}</p> */}
                      <Excerpt
                        length={320}
                        showReadMore
                        text={filteredTopics[filteredTopicsIndex].description}
                      />
                    </TextWrapper>
                  </Header>
                </AnimatePresence>
                <Form>
                  {data.map((c: Criterion) => {
                    return (
                      <>
                        <Label>{c.label}</Label>
                        <RatingInput
                          activeOption={activeOptions ? activeOptions[c.value] : null}
                          options={c.data}
                          onToggle={option => handleRatingInputToggle(c.value, option)}
                        />
                      </>
                    )
                  })}

                  <Submit loading={loading} onClick={handleSubmit}>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
                        <path d="M17 21v-8H7v8M7 3v5h8" />
                      </svg>
                    </div>
                    <span>Submit</span>
                  </Submit>
                </Form>
              </>
            )
          )}
        </Widget>
      </Wrapper>
    </Layout>
  )
}

export default RatingPage

// ================================================================================================

const Wrapper = styled.section`
  background: ${({ theme }) => theme.base};
  padding: 3rem 4rem;
`

const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
  margin: 0 0 4rem;
`

const Widget = styled.div`
  background: ${({ theme }) => theme.surface};
  padding: 1rem 2rem 2rem;
  width: 100%;
  max-width: 960px;
  margin-bottom: 1rem;
`

const WidgetNav = styled.nav`
  width: 100%;
  height: 2.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.base}`};
  margin: 0 0 1.75rem;

  button {
    display: flex;
    align-items: center;

    span {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.75rem;
      color: #c5cdd6;
      margin: 0 0.5rem;
      transition: 0.25s;
    }

    svg {
      width: 14px;
      height: 14px;
      fill: #c5cdd6;
      transition: 0.25s;
    }

    &:hover {
      span {
        color: ${({ theme }) => theme.text};
      }

      svg {
        fill: ${({ theme }) => theme.text};
      }
    }
  }
`

const Header = styled(motion.header)`
  display: flex;
  margin: 0 0 1.25rem;
`

interface ThumbProps {
  src?: string
}
const Thumb = styled(motion.div)<ThumbProps>`
  width: 6rem;
  height: 6rem;
  background-color: ${({ theme }) => theme.base};
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  flex: 0 0 6rem;
  margin: 0 1.5rem 0 0;
  border-radius: 2px;
`

const TextWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.35rem;
    color: ${({ theme }) => theme.text};
    margin: 0 0 0.75rem;
  }

  p {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.text};
    line-height: 1.4;
  }

  b {
    font-size: 0.875rem;
  }
`

const Form = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    &:first-child {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      > div {
        margin-bottom: 1.25rem;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
`

const Label = styled.label`
  font-size: 0.75rem;
  margin: 1.25rem 0 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
`

interface SubmitProps {
  loading?: boolean
}
const Submit = styled.button<SubmitProps>`
  height: 2.75rem;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2cb1bc;
  border-radius: 1.5rem;
  margin: 3rem 0 0;
  pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};

  svg {
    width: 1rem;
    height: 1rem;
    margin: 3px 0 0;
  }

  span {
    font-size: 0.8125rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    margin: 0 0 0 0.5rem;
    line-height: 1;
  }

  &:hover {
    background: #299ba5;
  }
`
