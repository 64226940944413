import React, { useState } from 'react'
import styled from 'styled-components'
import { subMonths } from 'date-fns'
import formatter from 'numeral'
import { motion } from 'framer-motion'

// API
import { useNewsLoader } from '../api'

// Components
import Article from '../components/Article'
import { DateFilter, FilterMenu, LanguageFilter } from '../components/Filter'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import LoadMore from '../components/LoadMore'
import NoResults from '../components/NoResults'
import Search from '../components/Search'
import TopicFilter from '../components/TopicFilter'

// Types
import { DateRange, SortBy, TimePeriod, Type } from '../types'

// ================================================================================================

const NewsPage = () => {
  const PAGE_SIZE = 50

  // ============================================
  // State

  // Date range is a potential date range for all data
  const [dateRange, setDateRange] = useState([subMonths(new Date(), 29), new Date().getTime()])
  // All states prepended by 'filter' are user set filters
  const [filterDateRange, setFilterDateRange] = useState<DateRange | null>(null)
  const [filterLanguages, setFilterLanguages] = useState([])
  const [filterTimePeriod, setFilterTimePeriod] = useState<TimePeriod | null>(null) // quick filter
  // Load more
  const [page, setPage] = useState(1)
  // Page search
  const [search, setSearch] = useState('')
  // Results sorting
  const [sortBy, setSortBy] = useState<SortBy>('publishedAt')
  // Data source
  const [source, setSource] = useState<Type>('all')

  // ============================================
  // Handler

  // News API allows only 1 language filter at the time
  const handleLanguageToggle = (language: string) => {
    setPage(1)

    if (filterLanguages.find(l => l === language)) {
      setFilterLanguages([])
    } else {
      setFilterLanguages([language])
    }
  }

  const handleLoadMore = () => {
    setPage(prev => prev + 1)
  }

  const handleSearch = (input: string) => {
    setPage(1)
    setSearch(input)
  }

  const handleSort = (sortBy: SortBy) => {
    setPage(1)
    setSortBy(sortBy)
  }

  const handleTabFilter = (filter: Type) => {
    setPage(1)
    setSource(filter)
  }

  // ============================================
  // Data

  const dateFilter = filterDateRange ? filterDateRange : filterTimePeriod ? filterTimePeriod : null

  const { count, loading, news } = useNewsLoader({
    dateFilter,
    language: filterLanguages[0] ? filterLanguages[0] : '',
    page,
    pageSize: PAGE_SIZE,
    q: search,
    sortBy,
    type: source
  })

  // ============================================
  // Render

  return (
    <Layout>
      <Header>
        <HeaderContainer>
          {/* <Heading>News</Heading> */}

          {/* <Label>Search Term</Label> */}
          <Search onSubmit={handleSearch} showSaveBtn />

          <Label>Trend Topics</Label>
          <TopicFilter />
        </HeaderContainer>
      </Header>

      <Content>
        <FilterWrapper>
          <TabFilter>
            <Tab active={source === 'all'} onClick={() => handleTabFilter('all')}>
              All
            </Tab>
            <Tab active={source === 'news'} onClick={() => handleTabFilter('news')}>
              News
            </Tab>
            <Tab
              active={source === 'earnings-calls'}
              onClick={() => handleTabFilter('earnings-calls')}
            >
              Earnings Call
            </Tab>
          </TabFilter>
          <FilterMenu>
            <DateFilter
              dateFilter={filterDateRange}
              dateRange={dateRange}
              first
              onReset={() => {
                setPage(1)
                setFilterDateRange(null)
                setFilterTimePeriod(null)
              }}
              onSetDateFilter={(filter: DateRange) => {
                setPage(1)
                setFilterDateRange(filter)
              }}
              onSetTimePeriod={(period: TimePeriod) => {
                setPage(1)
                setFilterTimePeriod(period)
              }}
              timePeriod={filterTimePeriod}
            />
            <LanguageFilter onToggle={handleLanguageToggle} selected={filterLanguages} />
          </FilterMenu>
        </FilterWrapper>

        <Articles>
          <ArticlesHeader>
            <NumOfResults>
              <b>{formatter(count).format('0,0')}</b> {count === 1 ? 'Result' : 'Results'}
            </NumOfResults>

            <Sorting>
              <b>Sort by:</b>
              <SortingButton
                active={sortBy === 'publishedAt'}
                onClick={() => handleSort('publishedAt')}
              >
                <svg
                  height="15px"
                  viewBox="0 0 128 128"
                  width="15px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M28 76a4 4 0 100-8 4 4 0 000 8zM32 95a4 4 0 11-8 0 4 4 0 018 0zM52 99a4 4 0 100-8 4 4 0 000 8zM76 99a4 4 0 100-8 4 4 0 000 8zM100 99a4 4 0 100-8 4 4 0 000 8zM56 72a4 4 0 11-8 0 4 4 0 018 0zM80 72a4 4 0 11-8 0 4 4 0 018 0zM104 72a4 4 0 11-8 0 4 4 0 018 0z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32 4a3 3 0 00-3 3v8H10a4 4 0 00-4 4v100a4 4 0 004 4h108a4 4 0 004-4V19a4 4 0 00-4-4H99V7a3 3 0 10-6 0v8H67V7a3 3 0 10-6 0v8H35V7a3 3 0 00-3-3zM12 21h17v8a3 3 0 106 0v-8h26v8a3 3 0 106 0v-8h26v8a3 3 0 106 0v-8h17v24H12V21zm0 30v66h104V51H12z"
                  />
                </svg>
                <span>Publication Date</span>
              </SortingButton>

              <SortingButton
                active={sortBy === 'relevance'}
                onClick={() => handleSort('relevance')}
              >
                <svg
                  height="15px"
                  viewBox="0 0 128 128"
                  width="15px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M60.393 9.584c1.452-3.028 5.762-3.028 7.214 0l15.899 33.16 36.807 5c3.344.453 4.658 4.588 2.19 6.889L95.77 79.553l7.268 36.34c.655 3.274-2.753 5.862-5.73 4.352l-33.299-16.877-32.822 16.867c-2.959 1.52-6.372-1.025-5.76-4.295l6.82-36.37-26.75-24.937c-2.468-2.3-1.154-6.436 2.19-6.89l36.807-4.998 15.9-33.161zM64 15.939L49.438 46.311a4 4 0 01-3.068 2.234l-33.695 4.576 24.468 22.81a4 4 0 011.204 3.663l-6.27 33.438 30.098-15.467a4 4 0 013.636-.01l30.536 15.477-6.68-33.397a4 4 0 011.195-3.71l24.463-22.804-33.695-4.576a4 4 0 01-3.068-2.234L64 15.939z"
                  />
                </svg>
                <span>Relevance</span>
              </SortingButton>
            </Sorting>
          </ArticlesHeader>

          {loading && (
            <LoadingSpinner>
              <Loader color="#102a43" />
            </LoadingSpinner>
          )}
          {!!(!loading && count === 0) && <NoResults />}
          {!!((!loading && !!(news && news.length)) || (loading && news.length)) &&
            news.map((article, i) => {
              return <Article data={article} key={`article-${i}`} />
            })}

          {!!(count > 0 && PAGE_SIZE * page < count && (!loading || (loading && news.length))) && (
            <LoadMore active={loading && page > 1} onClick={handleLoadMore} />
          )}
        </Articles>
      </Content>
    </Layout>
  )
}

export default NewsPage

// ================================================================================================

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0 2rem;
  background: ${({ theme }) => theme.surface};
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.02);
`

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  padding: 0 2rem;
  margin: 0 auto;
`

const Label = styled.label`
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.light2};
  font-weight: bold;
  margin-bottom: 0.75rem;
`

const LoadingSpinner = styled.div`
  height: 10rem;
  width: 100%;
`

const Content = styled.main`
  width: 100%;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4rem 2rem;
  margin: 0 auto;
`

const FilterWrapper = styled.aside`
  position: sticky;
  top: 3rem;
  flex: 0 0 360px;
  height: 3rem;
`

const TabFilter = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: none;
`

const Tab = styled.button`
  height: 3rem;
  padding: 0 1.5rem;
  flex: 0.32;
  background: ${({ active, theme }) => (active ? theme.surface : '#f4f6f7')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.text};
  line-height: 1.1;

  &:first-child {
    border-radius: 4px 0 0 0;
  }
  &:last-child {
    border-radius: 0 4px 0 0;
  }
`

const ArticlesHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: transparent;
  padding: 0;
  margin: 0 0 1.5rem;
`

const NumOfResults = styled.span`
  font-size: 0.875rem;
`

const Sorting = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > b {
    font-size: 0.8125rem;
  }
`

const SortingButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  span {
    font-weight: bold;
    font-size: 0.8125rem;
    color: ${({ active, theme }) => (active ? theme.text : '#aeb5bb')};
    margin-right: 0.375rem;
    transition: 0.25s;
  }

  svg {
    fill: ${({ active, theme }) => (active ? theme.text : '#9ea5ad')};
    transition: 0.25s;
    margin-right: 0.375rem;
  }

  &:hover {
    cursor: ${({ active }) => (active ? 'default' : 'pointer')};

    span {
      color: ${({ active, theme }) => (active ? theme.text : theme.highlight)};
    }

    svg {
      fill: ${({ active, theme }) => (active ? theme.text : theme.highlight)};
    }
  }
`

const Articles = styled.div`
  position: relative;
  width: 100%;
  margin-left: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
