import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// ================================================================================================

const FilterMenu: FC = ({ children }) => {
  const [selectedFacets, setSelectedFacets] = useState([])

  return (
    <Wrapper animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
      <Header>
        <span>{`Filter${selectedFacets.length > 0 ? ` (${selectedFacets.length})` : ''}`}</span>
      </Header>

      {children}
    </Wrapper>
  )
}

export default FilterMenu

// ================================================================================================

const Wrapper = styled(motion.div)`
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 1.5rem;
  background: ${({ theme }) => theme.surface};
  border-radius: 0 4px 4px 4px;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;

  span {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => theme.text};
  }
`
