import React from 'react'
import styled from 'styled-components'

// Components
import Loader from '../Loader'

// Partials
import Topics from './Topics'

// Store
import { useTopicStore } from '../../store'

// ================================================================================================

export interface TopicFilterProps {}

const TopicFilter: React.FC<TopicFilterProps> = ({}) => {
  const { activeTopics, loading, topics } = useTopicStore()

  return (
    <Wrapper>
      {loading ? (
        <LoadingSpinner>
          <Loader size={24} stroke={3} />
        </LoadingSpinner>
      ) : (
        <Topics activeTopics={activeTopics} topics={topics} />
      )}
    </Wrapper>
  )
}

export default TopicFilter

// ================================================================================================

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    width: 1.5rem;
    height: 100%;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    width: 1.5rem;
    height: 100%;
    z-index: 1;
  }
`

const LoadingSpinner = styled.div`
  height: 2.375rem;
  width: 4rem;
`
