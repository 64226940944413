import React, { createContext, FC, useState } from 'react'

// ================================================================================================

export const AppContext = createContext({
  scrollPosY: 0,
  setScrollPosY: (pos: number) => {}
})

// ================================================================================================

const AppStore: FC = ({ children }) => {
  const [scrollPosY, setScrollPosY] = useState(0)

  return (
    <AppContext.Provider
      value={{
        scrollPosY,
        setScrollPosY
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppStore
