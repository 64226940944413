const stripHTMLTags = (str: string) => {
  if (!str) return ''
  else str = str.toString()
  str = str.replace(/<li>|<ul>|<br\/>|<br \/>/g, ' ')
  str = str.replace(/<\/li>|<\/ul>/g, ', ')
  str = str.replace(/<[^>]*>/g, '')
  str = str.replace(/^\s*/g, '')
  return str
}

export default stripHTMLTags
