import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { subMonths, subWeeks } from 'date-fns'

// API
import { useNewsLoader } from '../../api'

// Components
import Barometer from '../Barometer'
import Loader from '../Loader'
import { DateFilter } from '../Filter'

// Store
import { useTopicStore } from '../../store'

// Types
import { DateRange, SortBy, TimePeriod, Topic } from '../../types'

// Utils
import { formatDate, getExcerpt } from '../../utils/string'

// ================================================================================================

interface Props extends RouteComponentProps {
  momentumFilter?: 'week' | 'month' | 'quarter'
  onDetailsClick: (topic: Topic) => void
  topic: Topic
  totalNewsCount: number
}

const ItemContent: FC<Props> = ({
  history,
  momentumFilter,
  onDetailsClick,
  topic,
  totalNewsCount
}) => {
  const { id } = topic

  const { toggleActiveTopic } = useTopicStore()

  const [dateRangeLast30, setDateRangeLast30] = useState([
    subMonths(new Date(), 1).getTime(),
    new Date().getTime()
  ])
  const [dateRangePrev30, setDateRangePrev30] = useState([
    subMonths(new Date(), 2).getTime(),
    subMonths(new Date(), 1).getTime()
  ])

  const [filterDateRange, setFilterDateRange] = useState<DateRange | null>(null)
  const [filterTimePeriod, setFilterTimePeriod] = useState<TimePeriod | null>('week')
  const [language, setLanguage] = useState('en')
  const [loadingMomentum, setLoadingMomentum] = useState(true)
  const [loadingStrength, setLoadingStrength] = useState(true)
  const [momentum, setMomentum] = useState<number | null>(null)
  const [showSettings, setShowSettings] = useState<string | null>(null)
  const [sortBy, setSortBy] = useState<SortBy>('relevance')
  const [strength, setStrength] = useState<number | null>(null)

  const dateFilter = filterDateRange ? filterDateRange : filterTimePeriod ? filterTimePeriod : null

  useEffect(() => {
    if (momentumFilter) {
      switch (momentumFilter) {
        case 'week': {
          setDateRangeLast30([subWeeks(new Date(), 1).getTime(), new Date().getTime()])
          setDateRangePrev30([subWeeks(new Date(), 2).getTime(), subWeeks(new Date(), 1).getTime()])

          break
        }
        case 'month': {
          setDateRangeLast30([subMonths(new Date(), 1).getTime(), new Date().getTime()])
          setDateRangePrev30([
            subMonths(new Date(), 2).getTime(),
            subMonths(new Date(), 1).getTime()
          ])

          break
        }
        case 'quarter': {
          setDateRangeLast30([subMonths(new Date(), 3).getTime(), new Date().getTime()])
          setDateRangePrev30([
            subMonths(new Date(), 6).getTime(),
            subMonths(new Date(), 3).getTime()
          ])

          break
        }

        default: {
          break
        }
      }
    }
  }, [momentumFilter])

  const { count, loading, news: topNews } = useNewsLoader({
    dateFilter,
    language,
    pageSize: 3,
    searchInTitle: false,
    sortBy,
    topics: [topic]
  })

  const { count: countReference } = useNewsLoader({
    // language,
    pageSize: 0,
    searchInTitle: false,
    sortBy,
    topics: [topic]
  })

  const { count: countLast30 } = useNewsLoader({
    dateFilter: dateRangeLast30,
    // language,
    pageSize: 0,
    searchInTitle: false,
    sortBy,
    topics: [topic]
  })

  const { count: countPrev30 } = useNewsLoader({
    dateFilter: dateRangePrev30,
    // language,
    pageSize: 0,
    searchInTitle: false,
    sortBy,
    topics: [topic]
  })

  useEffect(() => {
    if (countReference) {
      const strengthResult = (countReference / totalNewsCount) * 100
      if (
        typeof strengthResult === 'number' &&
        !isNaN(strengthResult) &&
        isFinite(strengthResult)
      ) {
        setStrength(strengthResult)
      }

      if (countLast30 && countPrev30) {
        const momentumResult = (countLast30 / countPrev30) * 50

        if (
          typeof momentumResult === 'number' &&
          !isNaN(momentumResult) &&
          isFinite(momentumResult)
        ) {
          setMomentum(momentumResult)
        }
      }
    }
  }, [count, countLast30, countPrev30, countReference, totalNewsCount])

  useEffect(() => {
    if (momentum || momentum === 0) {
      setLoadingMomentum(false)
    }
  }, [momentum])

  useEffect(() => {
    if (strength || strength === 0) {
      setLoadingStrength(false)
    }
  }, [strength])

  // ============================================

  const handleSignalsClick = id => {
    toggleActiveTopic(id)
    history.push('/scout/signals')
  }

  // ============================================

  return (
    <Wrapper>
      <ContentHeader>
        <Barometers>
          <Barometer label="Strength" loading={loadingStrength} value={strength} />
          <Barometer label="Momentum" loading={loadingMomentum} value={momentum} />
        </Barometers>

        <SettingsButton onClick={() => setShowSettings(id)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.002 512.002">
            <path d="M496.647 312.107l-47.061-36.8a171.245 171.245 0 000-38.656l47.104-36.821c8.827-7.109 11.186-19.575 5.568-29.419l-48.96-84.629c-5.639-9.906-17.649-14.232-28.309-10.197L369.522 97.9a190.011 190.011 0 00-33.323-19.349l-8.512-58.923C326.152 8.316 316.447-.092 305.031.001h-98.133c-11.321-.068-20.948 8.246-22.528 19.456l-8.533 59.093a197.046 197.046 0 00-33.28 19.371L86.94 75.563c-10.55-4.159-22.549.115-28.096 10.005L9.841 170.347c-5.769 9.86-3.394 22.463 5.568 29.547l47.061 36.8a169.628 169.628 0 000 38.656l-47.104 36.8c-8.842 7.099-11.212 19.572-5.589 29.419l48.939 84.651c5.632 9.913 17.649 14.242 28.309 10.197l55.467-22.315a190.885 190.885 0 0033.344 19.371l8.533 58.88c1.502 11.282 11.147 19.694 22.528 19.648h98.133c11.342.091 21-8.226 22.592-19.456l8.533-59.093a197.434 197.434 0 0033.28-19.371l55.68 22.379c10.55 4.149 22.543-.122 28.096-10.005l49.152-85.12c5.503-9.83 3.084-22.196-5.716-29.228zm-240.683 50.56c-58.91 0-106.667-47.756-106.667-106.667s47.756-106.667 106.667-106.667S362.631 197.089 362.631 256c-.071 58.882-47.786 106.597-106.667 106.667z" />
          </svg>
        </SettingsButton>
      </ContentHeader>

      <News>
        {loading ? (
          <LoadingSpinner>
            <Loader size={28} stroke={5} />
          </LoadingSpinner>
        ) : topNews && topNews.length ? (
          topNews.map((data, i) => {
            const { publishedAt, source, title, url } = data

            return (
              <NewsItem
                animate={{ opacity: 1 }}
                href={url ? url : '#'}
                key={`board-news-item-${i}`}
                initial={{ opacity: 0 }}
                target="_blank"
              >
                <NewsContent>
                  {(source || publishedAt) && (
                    <NewsHeader>
                      {!!publishedAt && <span>{formatDate(publishedAt)}</span>}
                      {source && source.name && publishedAt && <span>{'•'}</span>}
                      {!!source && source.name && <span>{getExcerpt(source.name, 50)}</span>}
                    </NewsHeader>
                  )}
                  <Title>{getExcerpt(title, 90)}</Title>
                </NewsContent>
              </NewsItem>
            )
          })
        ) : (
          ''
        )}
      </News>

      <ContentFooter>
        <button onClick={() => onDetailsClick(topic)}>Evaluation</button>
        <button onClick={() => handleSignalsClick(id)}>Signals</button>
      </ContentFooter>

      {showSettings === id && (
        <Settings
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3, type: 'tween' }}
        >
          <CloseButton onClick={() => setShowSettings(null)}>
            <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M123.622 17.49L17.556 123.556 2 108 108.066 1.934l15.556 15.556z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M108.51 123.622L2.444 17.556 18 2l106.066 106.066-15.556 15.556z"
              />
            </svg>
          </CloseButton>

          <SettingsTitle>Show Signals by</SettingsTitle>
          <Select>
            <SelectButton
              active={sortBy === 'publishedAt'}
              onClick={() => setSortBy('publishedAt')}
            >
              Publish Date
            </SelectButton>
            <SelectButton active={sortBy === 'relevance'} onClick={() => setSortBy('relevance')}>
              Relevance
            </SelectButton>
          </Select>

          <SettingsTitle>Language</SettingsTitle>
          <Select>
            <SelectButton active={language === 'en'} onClick={() => setLanguage('en')}>
              EN
            </SelectButton>
            <SelectButton active={language === 'de'} onClick={() => setLanguage('de')}>
              DE
            </SelectButton>
          </Select>

          <SettingsTitle>Date</SettingsTitle>
          <DateFilter
            accordion={false}
            dateFilter={filterDateRange}
            onReset={() => {
              setFilterDateRange(null)
              setFilterTimePeriod(null)
            }}
            onSetDateFilter={(filter: DateRange) => {
              setFilterDateRange(filter)
            }}
            onSetTimePeriod={(period: TimePeriod) => {
              setFilterTimePeriod(period)
            }}
            timePeriod={filterTimePeriod}
          />
        </Settings>
      )}
    </Wrapper>
  )
}

export default withRouter(ItemContent)

// ================================================================================================

const Wrapper = styled.div`
  position: relative;
  background: #fff;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const LoadingSpinner = styled.div`
  padding: 2rem 0;
`

const ContentHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Barometers = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem;
  }
`

const SettingsButton = styled.button`
  opacity: 0.6;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: #979da5;
    margin: 0 0.5rem 0 0;
  }

  &:hover {
    opacity: 1;
  }
`

const News = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0 1.25rem;
`

const NewsItem = styled(motion.a)`
  width: 100%;
  border-radius: 3px;
  display: flex;
  margin-bottom: 1.5rem;
  cursor: pointer;
`

const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
`

const NewsHeader = styled.header`
  display: flex;
  margin: 0 0 0.375rem;

  span {
    color: #758696;
    font-size: 0.8125rem;
    margin-right: 0.375rem;
  }
`

const Title = styled.h3`
  font-size: 1rem;
  line-height: 1.25;
  color: ${({ theme }) => theme.text};
`

const ContentFooter = styled.footer`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  button {
    width: 48%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #293a51;
    border-radius: 4px;
    font-weight: 600;
    font-size: 0.875rem;

    &:last-child {
      color: #293a51;
      background: #dbe2eb;
    }

    &:hover {
      background: #182535;

      &:last-child {
        background: #c6cdd6;
      }
    }
  }
`

const Settings = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  padding: 2.25rem 1.5rem 1.5rem;
  overflow-y: auto;
`

const SettingsTitle = styled.h4`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.text};
  margin: 1.25rem 0 0.75rem;
`

const Select = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    &:first-child {
      border-radius: 4px 0 0 4px;
      border-right: 1px solid #fff;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
      border-left: 1px solid #fff;
    }
  }
`
interface SelectButtonProps {}
const SelectButton = styled.button<SelectButtonProps>`
  width: 50%;
  height: 2.5rem;
  background: ${({ active, theme }) => (active ? '#122A43' : theme.base)};
  font-size: 0.75rem;
  color: ${({ active, theme }) => (active ? '#fff' : theme.text)};
  font-weight: bold;

  &:hover {
    background: ${({ active }) => (active ? '#122A43' : '#e4e8ec')};
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: 34px;
  top: 27px;
  opacity: 0.6;

  svg {
    width: 1rem;
    height: 1rem;
    fill: #979da5;
  }

  &:hover {
    opacity: 1;
  }
`
