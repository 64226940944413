import * as Parse from 'parse'
import React, { SyntheticEvent, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'

// Assets
import backgroundImage from '../assets/img/bg.png'
import backgroundVideoMp4 from '../assets/video/bg.mp4'
import backgroundVideoWebm from '../assets/video/bg.webm'

// Components
import Loader from '../components/Loader'

// Store
import { useDialogStore, useUserStore } from '../store'

// ================================================================================================

const SignInPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { showErrorDialog } = useDialogStore()
  const {
    setDefaultQuery,
    setEmail: setUserEmail,
    setUserSignedIn,
    setUserIsAdmin,
    setUserIsExternal
  } = useUserStore()

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')

  Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY)
  // @ts-ignore
  Parse.serverURL = process.env.REACT_APP_PARSE_URL
  const signedInUser = Parse.User.current()

  const isAdmin = signedInUser && signedInUser.get('isAdmin')
  if (isAdmin) setUserIsAdmin(true)

  const isExternal = signedInUser && signedInUser.get('isExternal')
  if (isExternal) setUserIsExternal(true)

  const defaultQuery = signedInUser && signedInUser.get('defaultQuery')
  if (defaultQuery) setDefaultQuery(defaultQuery)

  if (signedInUser) history.push('/monitor/dashboard') // auto forward if signed in

  const handleSignIn = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!loading) {
      setLoading(true)

      try {
        if (email && password) {
          await Parse.User.logIn(email, password)

          setUserEmail(email)
          setUserSignedIn(true)
          history.push('/monitor/dashboard')
        } else {
          showErrorDialog('Error: please provide all credentials')
        }
      } catch (error) {
        showErrorDialog(`Error: ${error.code} ${error.message}`)
      }

      setLoading(false)
    }
  }

  // ============================================

  return (
    <Wrapper>
      <Video autoPlay loop muted>
        <source src={backgroundVideoWebm} type="video/webm" />
        <source src={backgroundVideoMp4} type="video/mp4" />
      </Video>

      <Content>
        <Title>
          <b>Foresight Intelligence</b> System
        </Title>

        <Form>
          <Textfield
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
            placeholder="Email"
            type="email"
            value={email}
          />
          <Textfield
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.currentTarget.value)
            }
            placeholder="Password"
            type="password"
            value={password}
          />
          <Submit onClick={handleSignIn}>
            {loading ? <Loader color="rgba(255, 255, 255, 0.9)" size={26} stroke={4} /> : 'Sign In'}
          </Submit>
        </Form>

        <SmallPrint>
          <a href="mailto:hello@intuitive-ai.de">Request Demo</a>
          <a href="#">Legal</a>
        </SmallPrint>
      </Content>
    </Wrapper>
  )
}

export default withRouter(SignInPage)

// ================================================================================================

const H = '46px'

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: ${`url(${backgroundImage})`};
  background-size: cover;
  background-position: center;

  @media (min-width: 1024px) and (orientation: landscape) {
    background-position: right bottom;
  }
`

const Video = styled.video`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 1;
  transition: opacity 5s;
  pointer-events: none;
`

const Content = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 960px;
  padding: 0 6vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const Title = styled.h1`
  font-size: 2rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.99);
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 300;
  letter-spacing: 0.5px;

  b {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 800;
    color: rgba(255, 255, 255, 0.99);
  }
`

const Form = styled.div`
  width: 100%;
  margin-top: 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

export const Textfield = styled.input`
  background-color: rgba(255, 255, 255, 0.25);
  border: none;
  color: #fff;
  font-size: 0.875rem;
  height: ${H};
  padding: 0 1.25rem;
  width: 100%;
  max-width: 20rem;
  margin: 0 0 1rem 0;
  border-radius: 1px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;

  &:focus {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.4);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.4);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.4);
  }

  @media (min-width: 1024px) {
    width: 20rem;
    max-width: none;
    margin: 0 0.5rem 0 0;
  }
`

export const Submit = styled.button`
  background-color: #2498a2;
  border-radius: 1px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  height: ${H};
  width: 100%;
  max-width: 20rem;
  text-transform: uppercase;

  &:hover {
    background-color: #28a4af;
  }

  @media (min-width: 1024px) {
    width: 12rem;
    max-width: none;
  }
`

const SmallPrint = styled.div`
  display: flex;
  align-items: center;

  a {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.67);
    text-transform: uppercase;
    margin: 1.75rem 1rem 0;

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
  }
`
