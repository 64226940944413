import React, { FC } from 'react'
import styled from 'styled-components'

// Types
import { CriterionData } from '../types'

// ================================================================================================

interface Props {
  activeOption?: string
  onToggle: (option: CriterionData) => void
  options?: Array<CriterionData>
}

const RatingInput: FC<Props> = ({ activeOption, onToggle, options }) => {
  return (
    <Wrapper>
      {options &&
        options.map((option: CriterionData, i: number) => (
          <Item
            active={activeOption && option.value === activeOption}
            key={`option-${i}`}
            onClick={() => onToggle(option)}
          >
            {option.label}
          </Item>
        ))}
    </Wrapper>
  )
}

export default RatingInput

// ================================================================================================

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface ItemProps {
  active?: boolean
}
const Item = styled.button<ItemProps>`
  height: 2.5rem;
  flex: 0 1 9rem;
  background: ${({ active, theme }) => (active ? '#122A43' : theme.base)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  color: ${({ active, theme }) => (active ? '#fff' : theme.text)};
  border-right: 3px solid #fff;
  font-weight: bold;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-right: none;
  }

  &:hover {
    background: ${({ active, theme }) => (active ? '#122A43' : '#e4e8ec')};
  }
`
