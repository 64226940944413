import { createGlobalStyle } from 'styled-components'

// ================================================================================================

const Style = createGlobalStyle`
  :root {
    --outer-padding: 3rem;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: 700;
  }

  a, button, p, span, b, i, li, label {
    font-family: 'Open Sans', Arial, sans-serif;
    color: ${({ theme }) => theme.text}
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
  }

  span {
    font-size: 1rem;
    line-height: 1;
  }

  a, button, svg {
    transition: .25s;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  button {
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
`

// ================================================================================================

export default Style
