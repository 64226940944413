import React, { useState } from 'react'
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

// Pages
import DashboardPage from './pages/dashboard'
import RatingPage from './pages/rating'
import MoodboardPage from './pages/moodboard'
import NewTopicPage from './pages/new'
import RadarPage from './pages/radar'
import RoadmapPage from './pages/roadmap'
import RequestPage from './pages/request'
import ScenarioPage from './pages/scenario'
import SignalsPage from './pages/signals'
import SignInPage from './pages/signin'
import SignUpPage from './pages/signup'

// Store
import { AppStore, DialogStore, TopicStore, UserStore } from './store'

// Style (global)
import AppStyles from './AppStyles'

// Theme
import { ThemeSelector, useTheme } from './theme'

// ================================================================================================

const App: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [selectedTheme, setSelectedTheme] = useState<ThemeSelector>('light')
  const theme = useTheme(selectedTheme)

  return (
    <ThemeProvider theme={theme}>
      <AppStore>
        <UserStore>
          <DialogStore>
            <TopicStore>
              <AppStyles />

              <Switch>
                <Route exact path="/" component={SignInPage} />
                <Route exact path="/sign-up" component={SignUpPage} />
                <Route exact path="/monitor" component={DashboardPage} />
                <Route path="/monitor/dashboard" component={DashboardPage} />
                <Route path="/monitor/moodboard" component={MoodboardPage} />
                <Route path="/monitor/radar" component={RadarPage} />
                <Route path="/monitor/roadmap" component={RoadmapPage} />
                <Route path="/monitor/scenario" component={ScenarioPage} />
                <Route exact path="/evaluate" component={RatingPage} />
                <Route path="/evaluate/rating" component={RatingPage} />
                <Route exact path="/scout" component={SignalsPage} />
                <Route path="/scout/signals" component={SignalsPage} />
                <Route exact path="/manage" component={RequestPage} />
                <Route path="/manage/request" component={RequestPage} />
                <Route path="/manage/new-topic" component={NewTopicPage} />
              </Switch>
            </TopicStore>
          </DialogStore>
        </UserStore>
      </AppStore>
    </ThemeProvider>
  )
}

// ================================================================================================

export default withRouter(App)
