import { useCallback, useEffect, useState } from 'react'
import useAsyncLoader from './useAsyncLoader'
import * as Parse from 'parse'

// Types
import { Bit, DateRange, TimePeriod, Topic } from '../types'

// Utils
import { getDateForFilter } from '../utils/date'
import { logError } from '../utils/error'

// ================================================================================================

interface Props {
  dateFilter?: DateRange | TimePeriod
  page?: number
  pageSize?: number
  search?: string
  topics?: Array<Topic>
  type?: string
  years?: Array<string>
}

interface Response {
  count: number
  error?: Error
  loading?: boolean
  roadmap?: Array<Bit>
}

const useRoadmapLoader = ({
  dateFilter,
  page = 1,
  pageSize = 50,
  search,
  topics,
  type,
  years
}: Props): Response => {
  const [roadmap, setRoadmap] = useState([])

  const { count, data: response, error, loading } = useAsyncLoader(
    // @ts-ignore
    useCallback(() => {
      const Bit = Parse.Object.extend('Bit')
      const query = new Parse.Query(Bit)
      // Topic constraint
      if (topics && topics.length) {
        const topicIds = topics.map((t: Topic) => t.identifier)
        query.containedIn('topic', topicIds)
      }

      // Free text search
      if (search && search.length > 1) {
        query.fullText('title', search)
      }

      // Date filter
      if (dateFilter) {
        const { dateStart, dateEnd } = getDateForFilter(dateFilter)

        if (dateStart) {
          try {
            const dateStartInMS = new Date(dateStart).getTime()
            query.greaterThanOrEqualTo('timestamp', dateStartInMS)
          } catch (error) {
            logError(error)
          }
        }

        if (dateEnd) {
          try {
            const dateEndInMS = new Date(dateEnd).getTime()
            query.lessThanOrEqualTo('timestamp', dateEndInMS)
          } catch (error) {
            logError(error)
          }
        }
      }

      // Type: news or earnings call
      if (type) {
        if (type === 'earnings-calls') query.equalTo('type', 'ec')
        if (type === 'news') query.equalTo('type', 'news')
      }

      // Years filter
      if (years && years.length) {
        query.containedIn('year', years)
      }

      query.ascending('year')
      query.skip((page - 1) * pageSize)
      query.limit(pageSize)

      // @ts-ignore
      query.withCount()

      return query.find().then(data => data)
    }, [dateFilter, page, pageSize, search, topics, type, years])
  )

  // Merge existing new with incoming news
  // This is needed for 'load more'
  useEffect(() => {
    if (response && Array.isArray(response)) {
      setRoadmap(prev => (page === 1 ? response : [...prev, ...response]))
    }
  }, [response])

  return {
    count,
    error,
    loading,
    roadmap: Array.isArray(roadmap)
      ? roadmap
          .map(t => ({
            date: t.get('date'),
            id: t.id,
            locations: t.get('locations'),
            organizations: t.get('locations'),
            persons: t.get('persons'),
            source: t.get('source'),
            title: t.get('title'),
            timestamp: t.get('timestamp'),
            topic: t.get('topic'),
            url: t.get('url'),
            year: t.get('year')
          }))
          .sort((a, b) => parseInt(a.year) - parseInt(b.year))
      : []
  }
}

export default useRoadmapLoader
