import React, { FC } from 'react'
import styled from 'styled-components'

// ================================================================================================

interface Props {
  headline?: string
  message?: string
}
const NoResults: FC<Props> = ({
  headline = 'No matching results',
  message = 'Try again using more general search terms or remove filters'
}) => {
  return (
    <Wrapper>
      <Icon viewBox="0 0 133 170">
        <path
          d="M93.527 139.834C67.99 139.834 50.454 153 38.15 153 25.843 153 0 145.959 0 89.615 0 33.272 34.767 25 48.301 25c63.38 0 90.452 114.834 45.226 114.834z"
          fill="#fff"
          fillOpacity=".7"
        />
        <path fill="#172941" d="M74.649 115.803l3.66-3.66L84.162 118l-3.66 3.659z" />
        <path
          d="M79.04 63.11c14.551 14.55 14.551 38.142 0 52.693-14.55 14.55-38.142 14.55-52.693 0-14.551-14.551-14.551-38.143 0-52.694 14.55-14.55 38.142-14.55 52.693 0z"
          fill="#D2D9E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.381 112.143c12.53-12.53 12.53-32.845 0-45.375-12.53-12.53-32.845-12.53-45.375 0-12.53 12.53-12.53 32.845 0 45.375 12.53 12.53 32.845 12.53 45.375 0zm3.66 3.66c14.55-14.551 14.55-38.143 0-52.694-14.552-14.55-38.143-14.55-52.694 0-14.551 14.551-14.551 38.143 0 52.694 14.55 14.55 38.142 14.55 52.693 0z"
          fill="#172941"
        />
        <rect
          x="76.113"
          y="124.585"
          width="14.49"
          height="39.33"
          rx="5"
          transform="rotate(-45 76.113 124.585)"
          fill="#B1B8BF"
        />
        <path
          fill="#fff"
          fillOpacity=".2"
          d="M88.554 122.39l1.464-1.464 18.296 18.296-1.464 1.464z"
        />
        <path
          d="M74.813 95.689a22.896 22.896 0 00.025-11.542c-3.156-12.304-15.69-19.72-27.993-16.564a23.056 23.056 0 00-5.2 2.028l-1.477-3.745a27.083 27.083 0 015.683-2.157c14.444-3.705 29.157 5 32.862 19.444a26.882 26.882 0 01.055 13.222l-3.955-.686z"
          fill="#fff"
          fillOpacity=".44"
        />
        <path
          fill="#D2D9E2"
          d="M82.32 14.584l8.974 7.966-3.319 3.74L79 18.323zM101.765 31.843l8.975 7.966-3.319 3.74-8.975-7.966zM100.386 20.591l7.965-8.975 3.74 3.32-7.966 8.974z"
        />
      </Icon>

      <Headline>{headline}</Headline>
      <P>{message}</P>
    </Wrapper>
  )
}

export default NoResults

// ================================================================================================

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
`

const Icon = styled.svg`
  width: 8rem;
  height: 8rem;
`

const Headline = styled.h2`
  font-size: 1rem;
  color: #132a44;
  margin: 2rem 0 0.5rem;
  text-align: center;
`

const P = styled.p`
  font-size: 0.875rem;
  color: #b2bbc4;
  text-align: center;
`
