import React, { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'

// Components
import Filter from './RadarFilter'
import Radar from './Radar'

// Context
import RadarContext from './RadarContext'

// Types
import {
  Dot,
  DotPosition,
  Option,
  Ring,
  Segment,
  SelectOptions,
  SelectOption,
  Color
} from './types'

// Utils
import { getDotPosition } from './utils'

// ================================================================================================

const COLORS = [
  '#38BEC9',
  '#647ACB',
  '#F7D070',
  '#14919B',
  '#98AEEB',
  '#F9DA8B',
  '#0A6C74',
  '#829AB1',
  '#486581'
]
interface Props {
  activeDots?: Dot[]
  cutoutInDegrees?: number
  dots?: Dot[]
  groupDotsBySegment?: boolean
  selectOptions?: SelectOptions
  singleColoredDots?: boolean
}

const R: React.FC<Props> = ({
  cutoutInDegrees,
  dots,
  groupDotsBySegment,
  selectOptions,
  singleColoredDots
}) => {
  const [activeDots, setActiveDots] = useState<Dot[]>([]) // selected in filter/detail view
  const [colorDiff, setColorDiff] = useState(true)
  const [cx, setCx] = useState(0)
  const [cy, setCy] = useState(0)
  const [colors, setColors] = useState<Color[]>([])
  const [colorsSelection, setColorsSelection] = useState<Option>({
    label: 'Need for Action',
    value: 'needForAction'
  })
  const [dotHighlighting, setDotHighlighting] = useState(true)
  const [dotPositions, setDotPositions] = useState(null)
  const [hovering, setHovering] = useState<{ dot: Dot; x?: number; y?: number } | null>(null)
  const [radius, setRadius] = useState(0)
  const [rings, setRings] = useState<Ring[]>([]) // active by default
  const [ringsSelection, setRingsSelection] = useState<Option>({
    label: 'Maturity',
    value: 'maturity'
  })
  const [segments, setSegments] = useState<Segment[]>([])
  const [segmentsSelection, setSegmentsSelection] = useState<Option>({
    label: 'Trend Driver',
    value: 'trendDriver'
  })

  // ============================================

  useEffect(() => {
    if (colorsSelection) {
      const selectedOption = selectOptions.find(
        (opt: SelectOption) => opt.value === colorsSelection.value
      )
      if (
        selectedOption &&
        selectedOption.data &&
        selectedOption.value !== segmentsSelection.value
      ) {
        setColors(
          selectedOption.data.map((c, i) => ({
            ...c,
            active: true,
            id: c.value,
            code: COLORS[i % COLORS.length]
          }))
        )
      }
    }
  }, [colorsSelection])

  useEffect(() => {
    if (ringsSelection) {
      const selectedOption = selectOptions.find(
        (opt: SelectOption) => opt.value === ringsSelection.value
      )
      if (
        selectedOption &&
        selectedOption.data &&
        selectedOption.value !== segmentsSelection.value
      ) {
        setRings(selectedOption.data.map(r => ({ ...r, active: true, id: r.value })))
      }
    }
  }, [ringsSelection])

  useEffect(() => {
    if (segmentsSelection) {
      const selectedOption = selectOptions.find(
        (opt: SelectOption) => opt.value === segmentsSelection.value
      )
      if (selectedOption && selectedOption.data && selectedOption.value !== ringsSelection.value) {
        setSegments(selectedOption.data.map(s => ({ ...s, active: true, id: s.value })))
      }
    }
  }, [segmentsSelection])

  // ============================================

  const handleAddDotPosition = (position: DotPosition) => {
    const filtered =
      dotPositions && dotPositions.filter((pos: DotPosition) => pos.id !== position.id)

    if (filtered) {
      setDotPositions([...filtered, position])
    } else {
      setDotPositions([position])
    }
  }

  const handleAddActiveDot = (dot: Dot) => {
    if (!activeDots.find(d => d.id === dot.id)) {
      setActiveDots([...activeDots, dot])
    }
  }

  const handleRemoveActiveDot = (dot: Dot) => {
    const filtered = activeDots.filter(d => d.id === dot.id)
    setActiveDots(filtered)
  }

  const handleHover = (dot?: Dot, x?: number, y?: number) => {
    if (dot) {
      if (x && y) {
        setHovering({ dot, x, y })
      } else {
        const filteredSegments = segments.filter((s: Segment) => s.active)
        const reversedRings = rings.filter((r: Ring) => r.active).reverse()
        const { x, y } = getDotPosition(
          radius,
          cx,
          cy,
          dot,
          dots,
          reversedRings,
          filteredSegments,
          ringsSelection && ringsSelection.value ? ringsSelection.value : '',
          segmentsSelection && segmentsSelection.value ? segmentsSelection.value : ''
        )
        setHovering({ dot, x, y })
      }
    } else {
      setHovering(null)
    }
  }

  const handleToggle = (type: 'dot' | 'ring' | 'segment', id: string) => {
    if (type === 'dot') {
      // TODO
    } else if (type === 'ring') {
      const toggledRing = rings && rings.find((r: Ring) => r.id === id)

      if (toggledRing) {
        if (toggledRing.active) {
          // Toggle from active to INactive
          setRings(rings.map((r: Ring) => (r.id === toggledRing.id ? { ...r, active: false } : r)))
        } else {
          // Toggle from INactive to active
          setRings(rings.map((r: Ring) => (r.id === toggledRing.id ? { ...r, active: true } : r)))
        }
      }
    } else {
      // Segment
      const toggledSegment = segments && segments.find((s: Segment) => s.id === id)

      if (toggledSegment) {
        if (toggledSegment.active) {
          // Toggle from active to INactive
          setSegments(
            segments.map((s: Segment) => (s.id === toggledSegment.id ? { ...s, active: false } : s))
          )
        } else {
          // Toggle from INactive to active
          setSegments(
            segments.map((s: Segment) => (s.id === toggledSegment.id ? { ...s, active: true } : s))
          )
        }
      }
    }
  }

  // ============================================

  return (
    <RadarContext.Provider
      value={{
        activeDots,
        addDotPosition: handleAddDotPosition,
        colorDiff,
        colorRange: COLORS,
        colors,
        colorsSelection,
        cutout: cutoutInDegrees,
        cx: cx,
        cy: cy,
        dotHighlighting,
        dotPositions,
        dots,
        groupDotsBySegment,
        handleAddActiveDot,
        handleRemoveActiveDot,
        handleHover,
        handleToggle,
        hovering,
        radius,
        rings,
        ringsSelection,
        segments,
        segmentsSelection,
        selectOptions: selectOptions
          ? selectOptions.map((opt: SelectOption) => ({ label: opt.label, value: opt.value }))
          : [],
        setActiveDots,
        setColorDiff,
        setColorsSelection,
        setCx,
        setCy,
        setDotHighlighting,
        setRadius,
        setRingsSelection,
        setSegmentsSelection,
        singleColoredDots
      }}
    >
      <Wrapper>
        <FilterWrapper>
          <Filter />
        </FilterWrapper>

        <RadarWrapper>
          <Radar />
        </RadarWrapper>
      </Wrapper>
    </RadarContext.Provider>
  )
}

// ================================================================================================

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const FilterWrapper = styled.aside`
  width: 20vw;
  min-width: 20rem;
  max-width: 24rem;
  height: auto;
  margin: 5vh 7vw 0 0;
`

const RadarWrapper = styled.section`
  position: relative;
  flex: 1;
`

// ================================================================================================

export default R
