import React, { FC } from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'

// ================================================================================================

type Type = 'error' | 'info' | 'success'

interface Props {
  onClose: () => void
  text?: string
  type: Type
}

const Dialog: FC<Props> = ({ onClose, text, type }) => {
  return (
    <Modal
      center
      onClose={onClose}
      open={!!type}
      showCloseIcon={false}
      styles={{
        modal: {
          padding: 0,
          width: '90%',
          maxWidth: '320px'
        }
      }}
    >
      <Wrapper>
        {type === 'error' && (
          <Icon type="error">
            <svg viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.528 2.283a3 3 0 014.037 1.058l.003.005 8.47 14.14.008.014A3.001 3.001 0 0120.48 22H3.519a3 3 0 01-2.565-4.5l.008-.014 8.47-14.14.858.514-.855-.519a3 3 0 011.093-1.058zm.618 2.094L2.682 18.506A1 1 0 003.536 20h16.927a1 1 0 00.854-1.494L12.855 4.379l-.001-.002a1 1 0 00-1.708 0z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 8a1 1 0 011 1v4a1 1 0 11-2 0V9a1 1 0 011-1zM11 17a1 1 0 011-1h.01a1 1 0 110 2H12a1 1 0 01-1-1z"
              />
            </svg>
          </Icon>
        )}
        {type === 'info' && (
          <Icon type="info">
            <svg viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" stroke="#000" strokeWidth="2" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 16a1 1 0 001-1v-4a1 1 0 10-2 0v4a1 1 0 001 1zM11 8a1 1 0 001 1h.01a1 1 0 100-2H12a1 1 0 00-1 1z"
              />
            </svg>
          </Icon>
        )}
        {type === 'success' && (
          <Icon type="success">
            <svg viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.707 3.293a1 1 0 010 1.414l-10 10a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L12 12.586l9.293-9.293a1 1 0 011.414 0z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1v-7a1 1 0 112 0v7a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h11a1 1 0 110 2H5z"
              />
            </svg>
          </Icon>
        )}

        <Content>
          <Title>{type === 'error' ? 'Error' : type === 'success' ? 'Success' : 'Info'}</Title>

          <Text>{text}</Text>
        </Content>

        <CloseButton onClick={onClose} type={type}>
          Ok
        </CloseButton>
      </Wrapper>
    </Modal>
  )
}

export default Dialog

// ================================================================================================

const Wrapper = styled.div`
  padding: 3rem 1rem 1rem;
`

const Content = styled.div`
  padding: 0 0.5rem;
`

const Title = styled.h3`
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  text-transform: uppercase;
  margin: 0 0 1rem;
  font-family: 'Montserrat', 'Open Sans', Arial, Helvetica, sans-serif;
  text-align: center;
`

const Text = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.text};
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 0.875rem;
`

const CloseButton = styled.button<Type>`
  width: 100%;
  height: 42px;
  background-color: ${({ type }) =>
    type === 'error' ? '#ff4758' : type === 'success' ? '#2DCD71' : '#FFB700'};
  color: rgba(255, 255, 255, 0.99);
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Montserrat', 'Open Sans', Arial, Helvetica, sans-serif;
  margin-top: 2rem;
`

const Icon = styled.div<Type>`
  height: 3.5rem;
  width: 3.5rem;
  position: absolute;
  top: -1.75rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ type }) =>
    type === 'error' ? '#ff4758' : type === 'success' ? '#2DCD71' : '#FFB700'};
  border: 3px solid #fff;
  border-radius: 50%;

  svg {
    height: 40%;
    width: 40%;
    fill: #fff;
  }
`
