const highlightYears = (text: string): string => {
  return text.replace(
    /2020|2021|2022|2023|2024|2025|2026|2027|2028|2029|2030|2031|2032|2033|2034|2035|2036|2037|2038|2039|2040|2041|2042|2043|2044|2045|2046|2047|2048|2049|2050|2051|2052|2053|2054|2055|2056|2057|2058|2059|2060/gi,
    function replace(match) {
      return `<b>${match}</b>`
    }
  )
}

export default highlightYears
