import * as React from 'react'
import styled from 'styled-components'

// ================================================================================================

export interface CheckboxProps {
  activeColor?: string
  checked?: boolean
  className?: string
  color?: string
  onChange: () => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  activeColor,
  className,
  checked,
  color,
  onChange
}) => {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <StyledCheckbox activeColor={activeColor} checked={checked} color={color}>
        <Icon height="16px" viewBox="0 0 24 24" width="16px">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  )
}

export default Checkbox

// ================================================================================================

const CheckboxContainer = styled.div`
  display: flex;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  strokewidth: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
interface StyledCheckboxProps {
  activeColor?: string
  checked?: boolean
}
const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: ${({ activeColor, checked, color }) =>
    checked && activeColor ? activeColor : checked ? '#132a44' : color ? color : '#f0f4f9'};
  border-radius: 1px;
  transition: all 150ms;

  box-shadow: none !important;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`
