import { useCallback, useEffect, useState } from 'react'
import useAsyncLoader from './useAsyncLoader'
import axios from 'axios'
import queryBuilderNews from './queryBuilderNews'
import { useTopicStore, useUserStore } from '../store'
import { DateRange, SortBy, TimePeriod, Topic, Type } from '../types'
import { getDateForFilter } from '../utils/date'

// ================================================================================================

interface Props {
  dateFilter?: DateRange | TimePeriod
  language?: string
  page?: number
  pageSize?: number
  sortBy?: SortBy
  q?: string
  searchInTitle?: boolean
  topics?: Array<Topic>
  type?: Type
}

interface Response {
  count?: number
  error?: Error
  loading?: boolean
  news?: Array<any>
}

const FALLBACK_QUERY =
  'AUDI OR BMW OR Daimler OR Kia OR Hyundai OR Jaguar OR "Land Rover" OR Mercedes OR Nissan OR Porsche OR Seat OR Telekom OR Tesla OR Toyota OR Volkswagen'

const useNewsLoader = ({
  dateFilter,
  language,
  page = 1,
  pageSize = 50,
  q = '',
  searchInTitle,
  sortBy,
  topics,
  type
}: Props): Response => {
  const { activeTopics, loading: loadingTopics } = useTopicStore()
  const { defaultQuery } = useUserStore()

  const [news, setNews] = useState([])

  // Fetch news async
  // Callback prevents reloads on re-render
  const { data: response, error, loading } = useAsyncLoader(
    useCallback(() => {
      const { dateStart, dateEnd } = getDateForFilter(dateFilter)

      const query = queryBuilderNews({
        activeTopics: topics ? topics : activeTopics,
        defaultQuery: defaultQuery ? defaultQuery : FALLBACK_QUERY,
        from: dateStart,
        language,
        page,
        pageSize,
        searchInTitle,
        sortBy,
        to: dateEnd,
        type,
        userQuery: q
      })

      return loadingTopics
        ? // Return empty promise as long as topics are not loaded
          // This prevents multiple unnecessary ajax requests
          new Promise((resolve, reject) => resolve({ data: null, loading: true }))
        : axios.get(query).then(data => data)
    }, [activeTopics, dateFilter, defaultQuery, language, page, pageSize, q, sortBy, type])
  )

  // Merge existing new with incoming news
  // This is needed for 'load more'
  useEffect(() => {
    if (
      response &&
      response.data &&
      response.data.articles &&
      Array.isArray(response.data.articles)
    ) {
      setNews(prev => (page === 1 ? response.data.articles : [...prev, ...response.data.articles]))
    }
  }, [response])

  return {
    count:
      response && response.data && (response.data.totalResults || response.data.totalResults === 0)
        ? response.data.totalResults
        : null,
    error,
    loading,
    news
  }
}

export default useNewsLoader
