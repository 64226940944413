import React from 'react'
import styled from 'styled-components'

// Components
import Board from '../components/Board'
import Layout from '../components/Layout'

// ================================================================================================

const DashboardPage = () => {
  return (
    <Layout>
      <Wrapper>
        <Board />
      </Wrapper>
    </Layout>
  )
}

// ================================================================================================

const Wrapper = styled.div`
  width: 100%;
  padding: 3rem 2rem;
`

export default DashboardPage
