import React from 'react'
import { useContext, useEffect } from 'react'
import { useHover } from 'react-use'
import styled, { css, keyframes } from 'styled-components'
import { Dot } from './types'
import RadarContext from './RadarContext'

// ================================================================================================

interface Props {
  dot: Dot
}

const RadarFilterDot: React.FC<Props> = ({ dot }) => {
  const { handleAddActiveDot, handleHover } = useContext(RadarContext)

  const [hoverable, hovered] = useHover(hovered => (
    <Button onClick={() => handleAddActiveDot(dot)}>
      <span>{dot.title}</span>
    </Button>
  ))

  useEffect(() => {
    if (hovered) {
      handleHover(dot)
    } else {
      handleHover()
    }
  }, [hovered])

  return hoverable
}

// ================================================================================================

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.0625 0 0.125rem;
  margin-bottom: 0.375rem;
  flex-shrink: 0;

  span {
    font-weight: 400;
    font-size: 0.8125rem;
    color: ${({ theme }) => theme.text};
    line-height: 1.2;
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.text};
    }
  }
`

// ================================================================================================

export default RadarFilterDot
