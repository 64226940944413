import React, { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// ================================================================================================

interface Props {
  color?: string
  label: string
  loading?: boolean
  size?: number // circle size
  strokeWidth?: number
  value?: number
}

const Barometer: FC<Props> = ({
  color = '#122A43',
  label,
  loading = true,
  size = 50,
  strokeWidth = 5,
  value = 0
}) => {
  const adjustedValue = value > 100 ? 100 : value

  const radius = (size - strokeWidth) / 2
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * adjustedValue) / 100

  return (
    <Wrapper>
      <Svg fill="none" height={size} viewBox={`0 0 ${size} ${size}`} width={size}>
        <Circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          shapeRendering="geometricPrecision"
          stroke="#EFF0F2"
          strokeWidth={`${strokeWidth}px`}
        />
        {!loading && (
          <Circle
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            shapeRendering="geometricPrecision"
            stroke={color}
            strokeWidth={`${strokeWidth}px`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset
            }}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
          />
        )}
        {!loading && (
          <Text
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle"
          >
            {`${adjustedValue.toFixed()}`}
          </Text>
        )}
      </Svg>
      <Label>{label}</Label>
    </Wrapper>
  )
}

export default Barometer

// ================================================================================================

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Svg = styled.svg`
  margin: 0 0 0.625rem;
`

const Circle = styled(motion.circle)`
  stroke-linecap: round;
  stroke-linejoin: round;
`

const Label = styled.span`
  font-size: 0.625rem;
  color: #cccdd0;
  text-transform: uppercase;
  font-weight: 700;
`

const Text = styled(motion.text)`
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: 800;
  fill: #122a43;
`
