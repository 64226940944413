import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

// Partials
import Checkbox from '../Checkbox'
import FilterHeader from './FilterHeader'

// ================================================================================================

interface Props {
  first?: boolean
  onToggle?: (language: string) => void
  selected?: Array<string>
}

const LanguageFilter: FC<Props> = ({ first, onToggle, selected }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <FilterHeader
        first={first}
        onToggleOpen={() => setOpen(prev => !prev)}
        open={open}
        title="Language"
      />

      <AnimatePresence initial={false}>
        {open && (
          <Content
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 250 },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Languages>
              {[
                'ar',
                'de',
                'en',
                'es',
                'fr',
                'he',
                'it',
                'nl',
                'no',
                'pt',
                'ru',
                'se',
                'ud',
                'zh'
              ].map((language, i) => {
                return (
                  <Language
                    animate={{ opacity: 1, y: 0 }}
                    initial={{ opacity: 0, y: 25 }}
                    transition={{
                      delay: i % 2 === 0 ? i * 0.025 : i * 0.025 - 0.025,
                      duration: 0.2
                    }}
                    key={`language-filter-item-${i}`}
                  >
                    <Checkbox
                      checked={!!selected.find(l => l === language)}
                      onChange={() => {
                        onToggle(language)
                      }}
                    />
                    <Label>{language.toUpperCase()}</Label>
                  </Language>
                )
              })}
            </Languages>
          </Content>
        )}
      </AnimatePresence>
    </>
  )
}

export default LanguageFilter

// ================================================================================================

const Content = styled(motion.div)`
  transform-origin: top center;
  width: 100%;
  padding: 0 0 0 0;
`

const Languages = styled.div`
  transform-origin: top center;
  height: 100%;
  padding: 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
`

const Language = styled(motion.label)`
  width: 46%;
  height: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const Label = styled.span`
  font-size: 0.875rem;
  line-height: 1.15;
  color: ${({ theme }) => (theme && theme.text ? theme.text : '#111')};
  margin-top: 0;
  margin-left: 0.5rem;
`
