import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { format, parseISO } from 'date-fns'
import { Waypoint } from 'react-waypoint'
import { Element, scroller } from 'react-scroll'
import styled from 'styled-components'

// Components
import Excerpt from '../components/Excerpt'
import LoadMore from '../components/LoadMore'

// Store
import { useAppStore } from '../store'

// Types
import { Bit as BitType } from '../types'

// Utils
import { getExcerpt, highlightYears } from '../utils/string'
import { logError } from '../utils/error'

// ================================================================================================

type GroupedRoadmapItem = {
  data: Array<BitType>
  year: string
}

type GroupedRoadmap = Array<GroupedRoadmapItem>

interface Props {
  data: any[]
  loadingMore?: boolean
  loadMoreVisible?: boolean
  onLoadMore: () => void
  years: Array<string>
}

const Roadmap: React.FC<Props> = ({ data, loadingMore, loadMoreVisible, onLoadMore, years }) => {
  const [currentYear, setCurrentYear] = useState(
    data && data[0] && data[0].year ? data[0].year : ''
  )

  const { scrollPosY } = useAppStore()

  // Group roadmap data by years
  const roadmap: GroupedRoadmap =
    data && Array.isArray(data) && data.length
      ? data.reduce((groupedData: GroupedRoadmap, bit: BitType, i: number) => {
          if (groupedData.find(grouped => grouped.year === bit.year)) {
            // Push in existing year group
            return groupedData.map((g: GroupedRoadmapItem) => {
              if (g.year === bit.year) {
                return {
                  ...g,
                  data: [...g.data, bit]
                }
              } else {
                return g
              }
            })
          } else {
            // create new group for current year
            return [
              ...groupedData,
              {
                data: [bit],
                year: bit.year
              }
            ]
          }
        }, [])
      : []

  return (
    <Wrapper animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
      <RoadMapWrapper>
        {roadmap &&
          roadmap.map((group, i) => (
            <Element key={`year-${group.year}`} name={group.year}>
              <Waypoint bottomOffset={'60%'} onEnter={() => setCurrentYear(group.year)}>
                <Year first={i === 0}>{group.year}</Year>
              </Waypoint>

              <BitGroup>
                {group.data.map((item, j) => {
                  let formattedDate

                  try {
                    if (item.date && typeof item.date === 'string')
                      formattedDate = format(parseISO(item.date), 'dd.MM.yyyy')
                    if (item.date && typeof item.date === 'object')
                      formattedDate = format(item.date, 'dd.MM.yyyy')
                  } catch (error) {
                    logError(error)
                  }

                  return (
                    <Bit even={j % 2 === 0} key={`${group.year}-item-${j}`}>
                      {(!!item.source || !!formattedDate) && (
                        <Header>
                          {!!!!formattedDate && <span>{formattedDate}</span>}
                          {item.source && item.source && !!formattedDate && <span>{'•'}</span>}
                          {!!item.source && item.source && (
                            <span>{getExcerpt(item.source, 50)}</span>
                          )}
                        </Header>
                      )}
                      {/* <Title
                        dangerouslySetInnerHTML={{
                          __html: getExcerpt(item.title, 160)
                        }}
                      /> */}
                      <Excerpt
                        externalLink={item.url}
                        length={160}
                        showReadMore
                        text={item.title}
                      />
                    </Bit>
                  )
                })}
              </BitGroup>
            </Element>
          ))}

        {loadMoreVisible && <LoadMore active={loadingMore} onClick={onLoadMore} />}
      </RoadMapWrapper>

      <Timeline scrollPosY={scrollPosY}>
        <Axis>
          {years &&
            years
              .sort((a, b) => parseInt(a) - parseInt(b))
              .map((year, i) => {
                return (
                  <Point active={currentYear === year} key={`timeline-year-${year}`}>
                    <span>{year}</span>
                    <svg height="14px" width="14px">
                      <circle cx="5.5px" cy="5.5px" r="5.5px" strokeWidth="2px"></circle>
                    </svg>
                  </Point>
                )
              })}
        </Axis>
      </Timeline>
    </Wrapper>
  )
}

export default Roadmap

// ================================================================================================

const HEADER = 223

const Wrapper = styled(motion.div)`
  display: flex;
`

const RoadMapWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`

const Year = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin: ${({ first }) => (first ? '0 0 1rem' : '2.5rem 0 1rem')};
`

const BitGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const Bit = styled.article`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.surface};
  border-radius: 4px;
  padding: 1.5rem 2rem;
  margin: 0 1.25rem 1rem 0;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.025);
  transition: 0.25s;

  &:hover {
    box-shadow: 0 6px 12px -4px rgba(0, 0, 0, 0.15);
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: ${({ even }) => (even ? 'initial' : '-10px')};
    right: ${({ even }) => (even ? '-10px' : 'initial')};
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-right: ${({ even }) => (even ? 'none' : `10px solid ${({ theme }) => theme.surface};`)};
    border-bottom: 10px solid transparent;
    border-left: ${({ even }) => (even ? `10px solid ${({ theme }) => theme.surface};` : 'none')};
  }

  .excerpt {
    p  {
      margin-bottom: 0;
      font-size: 0.875rem;
      font-weight: 400;
      color: ${({ theme }) => theme.text};
    }
    button {
      font-weight: 400;
      font-size: 0.875rem;

      b {
        font-size: 0.875rem;
      }
    }
  }
`

const Header = styled.header`
  display: flex;
  margin: 0 0 0.375rem;

  span {
    color: #758696;
    font-size: 0.8125rem;
    margin-right: 0.375rem;
  }
`

const Title = styled.p`
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.text};
  margin: 0;
  transition: 0.25s;

  &:after {
    clear: left;
  }
`

interface TimelineProps {
  scrollPosY?: number
}
const Timeline = styled.div<TimelineProps>`
  transition: 0.25s;
  position: sticky;
  top: 4rem;
  flex: 0 0 8rem;
  width: 4rem;
  height: ${({ scrollPosY }) =>
    scrollPosY >= 224 ? `calc(100vh - 5vh - 5vh)` : `calc(100vh - 5vh - 5vh - ${HEADER}px)`};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Axis = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 7px;
    display: block;
    width: 2px;
    height: 100%;
    z-index: 0;
    background: #c0c6cc;
    border-radius: 1px;
  }
`

interface PointProps {
  active?: boolean
}
const Point = styled.div<PointProps>`
  height: 26px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;

  span {
    font-family: 'Montserrat', 'Open Sans', Arial, Helvetica, sans-serif;
    color: ${({ active }) => (active ? '#132a44' : '#c0c6cc')};
    font-size: ${({ active }) => (active ? '1.25rem' : '1rem')};
    font-weight: bold;
    margin-right: 0.5rem;
    transition: 0.25s;
  }

  svg {
    fill: ${({ active }) => (active ? '#132a44' : '#c0c6cc')};
    stroke: ${({ theme }) => theme.base};
    transition: 0.25s;
  }
`
