import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion, useMotionValue } from 'framer-motion'
import { Topic } from '../../types'
import { useScroll } from 'react-use'

// Assets
import defaultImg from '../../assets/img/trend.jpg'

// Store
import { useTopicStore } from '../../store'

// Types
import { Topic as TopicType } from '../../types'

// ================================================================================================

const OFFSET = 284

const animation = {
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  initial: { opacity: 0 },
  transition: { duration: 0.3, type: 'tween' }
}

export interface TopicsProps {
  activeTopics: Array<TopicType>
  topics: Array<TopicType>
}

const Topics: React.FC<TopicsProps> = ({ activeTopics, topics }) => {
  const { toggleActiveTopic } = useTopicStore()

  const [showScrollNext, setShowScrollNext] = useState(false)
  const [showScrollPrev, setShowScrollPrev] = useState(false)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [visibleWidth, setVisibleWidth] = useState(0)

  const scrollRef = React.useRef(null)
  const { x } = useScroll(scrollRef)

  // Effects

  useEffect(() => {
    if (scrollRef.current && scrollRef.current.scrollWidth) {
      setScrollWidth(scrollRef.current.scrollWidth)
      setVisibleWidth(scrollRef.current.offsetWidth)
    }
  }, [scrollRef.current])

  useEffect(() => {
    if (scrollWidth && visibleWidth) {
      if (x) {
        setShowScrollPrev(true)
      } else {
        setShowScrollPrev(false)
      }

      if (scrollWidth > x + visibleWidth) {
        setShowScrollNext(true)
      } else {
        setShowScrollNext(false)
      }
    }
  }, [scrollWidth, x])

  // Handler

  const handleScrollPrev = () => {
    if (scrollWidth && visibleWidth && scrollRef.current) {
      if (scrollRef.current.scrollLeft > OFFSET) {
        scrollRef.current.scrollLeft -= OFFSET
      } else {
        scrollRef.current.scrollLeft = 0
      }
    }
  }

  const handleScrollNext = () => {
    if (scrollWidth && visibleWidth && scrollRef.current) {
      if (scrollWidth - visibleWidth - scrollRef.current.scrollLeft > OFFSET) {
        scrollRef.current.scrollLeft += OFFSET
      } else {
        scrollRef.current.scrollLeft = scrollWidth - visibleWidth
      }
    }
  }

  // Render

  return (
    <Wrapper ref={scrollRef}>
      {topics.map((t: Topic, i: number) => {
        const { id, image, imageUrl, title } = t
        const active =
          !!activeTopics && activeTopics.find((activeTopic: Topic) => activeTopic.id === id)

        return (
          <Item
            active={active}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            key={id}
            onClick={() => toggleActiveTopic(id)}
            transition={{ delay: i * 0.05, duration: 0.25, type: 'tween' }}
          >
            <Thumb
              image={image && typeof image === 'string' ? image : imageUrl ? imageUrl : null}
            />
            <h2>{title}</h2>
            {active && (
              <CloseIcon viewBox="0 0 16 16">
                <path
                  d="M14.435 1.707a1 1 0 00-1.414 0l-4.95 4.95-4.95-4.95a1 1 0 00-1.414 1.414l4.95 4.95-4.95 4.95a1 1 0 101.414 1.414l4.95-4.95 4.95 4.95a1 1 0 101.414-1.414l-4.95-4.95 4.95-4.95a1 1 0 000-1.414z"
                  fill="#fff"
                />
              </CloseIcon>
            )}
          </Item>
        )
      })}

      <AnimatePresence>
        {showScrollPrev && (
          <Navigator
            animate="animate"
            exit="exit"
            initial="initial"
            onClick={handleScrollPrev}
            transition="transition"
            variants={animation}
          >
            <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.726 63.5L86 7.496 75.603 1.504 39.876 63.5l35.727 61.996L86 119.504 53.726 63.5z"
              />
            </svg>
          </Navigator>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showScrollNext && (
          <Navigator
            animate="animate"
            exit="exit"
            initial="initial"
            next
            onClick={handleScrollNext}
            transition="transition"
            variants={animation}
          >
            <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.274 63.5L40 7.496l10.397-5.992L86.124 63.5l-35.727 61.996L40 119.504 72.274 63.5z"
              />
            </svg>
          </Navigator>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

export default Topics

// ================================================================================================

const Wrapper = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

interface ItemProps {
  active?: boolean
}

const Item = styled(motion.button)<ItemProps>`
  flex: 0 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  border-radius: 1.375rem;
  padding: 0 1.5rem 0 0.375rem;
  margin: 0 0.75rem 0 0;
  background: ${({ active, theme }) => (active ? '#102A43' : theme.base)};

  h2 {
    color: ${({ active }) => (active ? '#fff' : '#ADB5BD')};
    font-size: 0.75rem;
    transition: 0.25s;
  }

  &:hover {
    background: ${({ active }) => (active ? '#102A43' : '#e2e6ea')};
  }

  &:last-child,
  &:first-child {
    z-index: 2;
  }
`

interface ThumbProps {
  image?: string
}
const Thumb = styled.div<ThumbProps>`
  background: rgba(255, 255, 255, 0.1);
  background-image: ${({ image }) => (image ? `url(${image})` : `url(${defaultImg})`)};
  background-size: cover;
  background-position: center;
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
  margin-right: 0.75rem;
`

const CloseIcon = styled.svg`
  position: absolute;
  right: 12px;
  top: 10px;
  width: 7px;
  height: 7px;
`

interface NavigatorProps {
  next?: boolean
}
const Navigator = styled(motion.button)<NavigatorProps>`
  position: absolute;
  top: 50%;
  right: ${({ next }) => (next ? '-2.5rem' : 'initial')};
  left: ${({ next }) => (next ? 'initial' : '-2.5rem')};
  transform: translateY(-50%);
  padding: 0.25rem 0.5rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: #cfd4d8;
  }

  &:hover {
    svg {
      fill: #afb4b9;
    }
  }
`
