import * as React from 'react'
import styled from 'styled-components'

// ================================================================================================

export interface TextAreaProps {
  height?: string
  label?: string
  onTextChange: (input: string) => void
  placeholder?: string
  value: string
  width?: string
}

// ================================================================================================

const TextArea: React.FC<TextAreaProps> = ({
  height,
  label,
  onTextChange,
  placeholder,
  value,
  width
}) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onTextChange(e.currentTarget.value)
  }

  return (
    <Wrapper width={width}>
      {!!label && <Label>{label}</Label>}

      <InputWrapper label={!!label}>
        <Input
          height={height}
          onChange={handleChange}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </InputWrapper>
    </Wrapper>
  )
}

// ================================================================================================

interface WrapperProps {
  width?: string
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child {
    margin-right: 1rem;
  }

  @media (min-width: 1024px) {
    width: ${({ width }) => (width ? width : '100%')};
  }
`

const Label = styled.label`
  font-size: 0.8125rem;
  margin: 0 0 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
`

const InputWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.base};
  padding: 1rem;
  border-radius: 4px;
`

interface InputProps {
  height?: string
}
const Input = styled.textarea<InputProps>`
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 0.9375rem;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.text};
  width: 100%;
  height: ${({ height }) => (height ? height : '3rem')};

  &:focus {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ced2d7;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ced2d7;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ced2d7;
  }
`

// ================================================================================================

export default TextArea
