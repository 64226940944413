import { differenceInDays, format, getTime, addDays, subDays, subMonths } from 'date-fns'

// Expects a percentage value and returns and date within a given date range
export const percentToDate = (
  start: number | Date,
  end: number | Date,
  percent: number,
  dateFormat?: string,
  formatToMs?: boolean
): string | number | Date => {
  // First, get all days between start and end = 100%, e.g. 365
  const daysTotal = Math.abs(differenceInDays(start, end))
  // Then get position as number, e.g. 365 * 20% = 73
  const selectedDateInDays = percent > 1 ? (daysTotal / 100) * percent : daysTotal * percent
  // Finally, add selected date days value to start date ...
  const currentDate = addDays(start, selectedDateInDays)
  // ...and return formatted date
  try {
    if (formatToMs) {
      const currentDateInMs = getTime(currentDate)
      return currentDateInMs
    } else {
      const defaultDateFormat = daysTotal > 60 ? 'MM/yyyy' : 'dd MMM'
      const resultDateFormat = dateFormat ? dateFormat : defaultDateFormat
      const result = format(currentDate, resultDateFormat)
      return result
    }
  } catch (error) {
    console.log(error)
    return '00/0000'
  }
}

// expects a date and a date range, returns a percentage value between 0 and 100
export const dateToPercent = (
  date: number | Date,
  start: number | Date,
  end: number | Date
): number => {
  try {
    // First, get all days between start and end = 100%, e.g. 365
    const daysTotal = differenceInDays(start, end)
    // Then get difference between start date and current date
    const startToSelected = differenceInDays(start, date)
    const result = (startToSelected * 100) / daysTotal
    if (result && typeof result === 'number' && !isNaN(result) && isFinite(result)) {
      return result
    }
    return 0
  } catch (error) {
    console.log(error)
    return 0
  }
}

export const getDefaultStartValue = ({ dateFilter, dateRangeStart, dateRangeEnd }) => {
  try {
    if (dateFilter) {
      if (Array.isArray(dateFilter) && dateFilter.length === 2) {
        return dateToPercent(dateFilter[0], dateRangeStart, dateRangeEnd)
      } else if (dateFilter === 'day' || dateFilter === 'week' || dateFilter === 'month') {
        switch (dateFilter) {
          case 'day': {
            const startDate = subDays(new Date(), 1)
            return dateToPercent(startDate, dateRangeStart, dateRangeEnd)
          }
          case 'week': {
            const startDate = subDays(new Date(), 7)
            return dateToPercent(startDate, dateRangeStart, dateRangeEnd)
          }
          case 'month': {
            const startDate = subMonths(new Date(), 1)
            return dateToPercent(startDate, dateRangeStart, dateRangeEnd)
          }
          default: {
            return 0
          }
        }
      } else {
        return 0
      }
    } else {
      return 0
    }
  } catch (error) {
    console.log(error)
    return 0
  }
}
