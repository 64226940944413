import React, { useMemo, useState, SyntheticEvent } from 'react'
import styled from 'styled-components'

// Components
import Layout from '../components/Layout'
import Trendradar from '../components/Trendradar'

// Store
import { useTopicStore } from '../store'

// Types
import { Topic } from '../types'

// Utils
import { data } from '../utils/radar'

// ================================================================================================

const Radar: React.FC = () => {
  const { topics: topicsStore } = useTopicStore()

  const topics = useMemo(
    () => (topicsStore && Array.isArray(topicsStore) && topicsStore.length ? topicsStore : []),
    [topicsStore]
  )

  return (
    <Layout>
      <Wrapper>
        <Widget>
          <Trendradar
            dots={topics}
            groupDotsBySegment
            selectOptions={data}
            singleColoredDots={false}
          />
        </Widget>
      </Wrapper>
    </Layout>
  )
}

export default Radar

// ================================================================================================

const Wrapper = styled.section`
  background: ${({ theme }) => theme.base};
  padding: 3rem 4rem;
`

const Widget = styled.section`
  position: relative;
  width: 100%;
  padding: 2vh 4vw 4vh;
  background: ${({ theme }) => theme.surface};
  border-radius: 4px;
`
