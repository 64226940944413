import React, { createContext, FC, useState } from 'react'
import { useTopicLoader } from '../api'
import { Topic } from '../types'

// ================================================================================================

const defaultActiveTopics: Array<Topic> = []
const defaultTopics: Array<Topic> = []

export const TopicContext = createContext({
  activeTopics: defaultActiveTopics,
  loading: false,
  toggleActiveTopic: (topicId: string) => {},
  topics: defaultTopics
})

// ================================================================================================

const TopicStore: FC = ({ children }) => {
  const [activeTopicIds, setActiveTopicIds] = useState<Array<string>>([])

  const { error, loading, topics } = useTopicLoader()

  const toggleActiveTopic = topicId => {
    if (activeTopicIds.find((tId: string) => tId === topicId)) {
      setActiveTopicIds(activeTopicIds.filter((tId: string) => tId !== topicId))
    } else {
      setActiveTopicIds([...activeTopicIds, topicId])
    }
  }

  return (
    <TopicContext.Provider
      value={{
        activeTopics: topics.filter(
          (t: Topic) => !!activeTopicIds.find((tId: string) => tId === t.id)
        ),
        loading,
        toggleActiveTopic,
        topics
      }}
    >
      {children}
    </TopicContext.Provider>
  )
}

export default TopicStore
