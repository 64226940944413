import React, { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// ================================================================================================

interface Props {
  active: boolean
  onClick: () => void
}

const LoadMore: FC<Props> = ({ active, onClick }) => {
  return (
    <Wrapper
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ delay: 1, duration: 0.5, type: 'tween' }}
    >
      <Loader onClick={!active ? onClick : () => {}}>
        <div>
          <Spinner active={active} viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.102 9C49.804 9 36.78 14.455 27 23.398l4.247 4.248C39.94 19.786 51.462 15 64.102 15c24.78 0 45.265 18.395 48.542 42.276h-7.888a1.5 1.5 0 00-1.287 2.27l10.345 17.301a1.5 1.5 0 002.575 0l10.346-17.301a1.5 1.5 0 00-1.288-2.27h-6.752C115.379 30.072 92.201 9 64.102 9zm-.254 109.577c14.297 0 27.32-5.455 37.102-14.398l-4.248-4.248c-8.69 7.86-20.213 12.646-32.854 12.646-24.78 0-45.265-18.395-48.542-42.276h7.888a1.5 1.5 0 001.287-2.27l-10.346-17.3a1.5 1.5 0 00-2.574 0L1.215 68.03a1.5 1.5 0 001.287 2.27h6.753c3.316 27.204 26.494 48.276 54.593 48.276z"
            />
          </Spinner>
        </div>
        <span>Load More</span>
      </Loader>
    </Wrapper>
  )
}

export default LoadMore

// ================================================================================================

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Loader = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 4rem;

  > div {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #102a43;
    border-radius: 50%;
    margin-bottom: 0.25rem;
    transition: 0.25s;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: #fff;
    transition: 0.25s;
  }

  span {
    font-size: 0.625rem;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => theme.text};
    transition: 0.25s;
  }

  &:hover {
    div {
      background-color: #102a43;
    }

    svg {
      fill: #fff;
      transform: rotate(360deg);
      transition-delay: 0.125s;
    }

    span {
      color: ${({ theme }) => theme.text};
    }
  }
`

const Spinner = styled(motion.svg)`
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: ${({ active }) => (active ? 'rotation 0.75s ease-in-out infinite' : 'none')};
  /* animation: rotation 0.75s ease-in-out infinite; */
`
