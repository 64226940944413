import { useContext } from 'react'
import { AppContext } from './AppStore'
import { DialogContext } from './DialogStore'
import { TopicContext } from './TopicStore'
import { UserContext } from './UserStore'

export const useAppStore = () => {
  return useContext(AppContext)
}
export const useDialogStore = () => {
  return useContext(DialogContext)
}
export const useTopicStore = () => {
  return useContext(TopicContext)
}
export const useUserStore = () => {
  return useContext(UserContext)
}
